import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import CustomDataGrid from "app/components/Shared/DataGrid/CustomDataGrid";
import CustomPagination from "app/shared/Pagination/Pagination.component";
import { socketURL } from "app/utils/axiosRequest";
import { useEffect, useState } from "react";
import socketIOClient from "socket.io-client";

const LiveCallsTableComponent = ({ tableData, data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [liveCalls, setLiveCalls] = useState([]);

  const columns = [
    { field: "receiver", headerName: "Contact Number", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 0,
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{
            color: params?.value === "RINGING" ? "#FFD700" : "#0000FF",
          }}
        >
          {params?.value}
        </Typography>
      ),
    },
    { field: "duration", headerName: "Duration", flex: 1 },
  ];

  useEffect(() => {
    const accessToken = localStorage.getItem("callCenterToken");
    const socket = socketIOClient(socketURL.liveUrl, {
      extraHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    socket.on("call-blasting-live-calls", (liveCampaignCalls) => {
      setLiveCalls(liveCampaignCalls);
    });
  }, []);

  // TODO: Implement pagination
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleFirstPageClick = () => {
    setCurrentPage(1);
  };

  const handleLastPageClick = () => {
    if (tableData.length > 0) {
      setCurrentPage(Math.ceil(tableData.length / 10));
    }
  };

  const handleLeftClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRightClick = () => {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(tableData.length / 10))
    );
  };

  const startIndex = (currentPage - 1) * 10;
  const endIndex = Math.min(startIndex + 10, tableData.length);
  const rows = tableData ? tableData.slice(startIndex, endIndex) : [];

  return (
    <Card
      sx={{
        height: "75.5vh",
        // boxShadow: "4px 2px 24px rgba(35, 35, 156, 0.2)",
        borderRadius: "14px",
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ ml: 2, mt: 1 }}>
              Live Calls
            </Typography>
          </Grid>
          {/* <Grid item xs={6}>
            <Typography
              fontSize={18}
              fontWeight={400}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-end"}
              sx={{ mr: 2, mt: 1 }}
            >
              {data.campaignName}
            </Typography>
          </Grid> */}
        </Grid>
        {/* <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              variant="body2"
              sx={{ ml: 2, py: 0.5, color: "#FFD700" }}
            >
              In progress : {data.inProgress}
            </Typography>
            <Typography
              variant="body2"
              sx={{ ml: 2, py: 0.5, color: "#0000FF", mb: 1 }}
            >
              Total : {data.total}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-end"}
          >
            <Typography
              variant="body2"
              sx={{ mr: 2, py: 0.5, color: "#008000" }}
            >
              Completed : {data.completed}
            </Typography>
            <Typography
              variant="body2"
              sx={{ mr: 2, py: 0.5, color: "#0000FF", mb: 1 }}
            >
              Estimated Time : {data.estimatedTime} hr
            </Typography>
          </Grid>
        </Grid> */}
        <Box sx={{ height: "50vh" }}>
          <Box
            sx={{
              marginBottom: 2,
              ml: 2,
              mr: 2,
            }}
          >
            <CustomDataGrid
              rows={liveCalls || []}
              columns={columns}
              height={"58vh"}
              getRowId={(row) => row?.receiver}
            />
          </Box>
          <CustomPagination
            currentPage={currentPage}
            totalPage={Math.ceil(tableData.length / 10)}
            handlePageClick={handlePageChange}
            handleFirstPageClick={handleFirstPageClick}
            handleLastPageClick={handleLastPageClick}
            handleLeftClick={handleLeftClick}
            handleRightClick={handleRightClick}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default LiveCallsTableComponent;
