import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useState } from "react";

const CustomSelectComponent = ({
  initialOptions,
  label,
  value,
  handleChange,
  name,
  required,
}) => {
  const [selectedValue, setSelectedValue] = useState(value || "");

  const handleSelectChange = (event) => {
    const selected = event.target.value;
    setSelectedValue(selected);
    handleChange(selected);
  };

  return (
    <FormControl
      fullWidth
      sx={{
        mb: 2,
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#23239C",
          },
          "&:hover fieldset": {
            borderColor: "#131313", // Border color on hover
          },
          "&.Mui-focused fieldset": {
            borderColor: "#23239C", // Border color when focused
          },
        },
        "& .MuiFormLabel-root": {
          color: "#23239C",
        },
      }}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        required={required}
        label={label}
        value={selectedValue}
        onChange={handleSelectChange}
        name={name}
      >
        {initialOptions?.map((option) => {
          return (
            <MenuItem key={option?.id} value={option?.id}>
              {option?.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default CustomSelectComponent;
