import { AddCircleOutlineOutlined, DeleteOutline } from "@mui/icons-material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import ConfirmDialogBox from "app/components/Shared/ConfirmDialogBox/ConfirmDialogBox";
import SnackbarComponent from "app/components/SnackBar";
import { adminTicketTypes } from "app/utils/api-urls/AdminAPiUrls";
import { instance } from "app/utils/axiosRequest";
import { useState } from "react";
import AddTicketTypeModal from "./AddTicketTypeModal";

const GetTicketTypes = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [editData, setEditData] = useState(null);
  const [mode, setMode] = useState("");
  const [snackbarData, setSnackbarData] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const { data, isLoading, isRefetching, refetch } = useQuery(
    ["admin-ticket-types"],
    () => instance.get(adminTicketTypes.admin_ticket_types),
    {
      onSuccess: (data) => {
        setEditData(data?.data);
      },
    }
  );

  const deleteTicketType = useMutation(
    ["delete-ticket-type"],
    (ticket) =>
      instance.delete(adminTicketTypes.admin_ticket_type_api + `/${ticket}`),
    {
      onSuccess: (response) => {
        setSnackbarData({
          open: true,
          severity: "success",
          message: "Ticket-Type Deleted!",
        });
        refetch();
        handleDeleteClose();
      },
      onError: () => {
        setSnackbarData({
          open: true,
          severity: "error",
          message: "Error Deleting Ticket-Type!",
        });
      },
    }
  );

  const handleAddTicketTypeModal = () => {
    setIsModalOpen(!isModalOpen);
    setEditData(null);
    setMode("add");
  };

  const handleSnackbarClose = () => {
    setSnackbarData({ ...snackbarData, open: false });
  };

  const handleSetSnackbarData = (newSnackbarData) => {
    setSnackbarData(newSnackbarData);
  };

  const handleDeleteOpen = (ticketId) => {
    setOpenDelete(true);
    setEditData({ _id: ticketId });
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  // delete api called
  const handleTicketTypeDelete = (ticketId) => {
    deleteTicketType.mutate(ticketId);
  };

  const handleEdit = (item) => {
    setIsModalOpen(true);
    setEditData(item);
    setMode("edit");
  };

  return (
    <>
      <SnackbarComponent
        open={snackbarData.open}
        handleClose={handleSnackbarClose}
        data={snackbarData}
      />
      {isLoading || isRefetching || deleteTicketType.isLoading ? (
        <FullpageLoader
          open={isLoading || isRefetching || deleteTicketType.isLoading}
        />
      ) : (
        <>
          <AddTicketTypeModal
            open={isModalOpen}
            handleClose={handleAddTicketTypeModal}
            refetch={refetch}
            mode={mode}
            data={editData}
            snackbarData={snackbarData}
            openSnackbar={snackbarData.open}
            handleSnackbarClose={handleSnackbarClose}
            setSnackbarData={handleSetSnackbarData}
            dataId={editData?._id}
          />
          {openDelete && (
            <ConfirmDialogBox
              open={openDelete}
              handleClose={handleDeleteClose}
              handleConfirm={() => handleTicketTypeDelete(editData?._id)}
              label={
                "Are you sure you want to delete this? This action cannot be undone."
              }
            />
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Tooltip title="Add New Ticket Type">
              <IconButton onClick={handleAddTicketTypeModal}>
                <AddCircleOutlineOutlined
                  sx={{ fontSize: "35px", color: "#001A72" }}
                />
              </IconButton>
            </Tooltip>
          </Box>
          <Box
            marginBottom="1rem"
            display="flex"
            flexDirection="row"
            gap="3rem"
            flexWrap="wrap"
          >
            {data?.data.map((item) => (
              <Card key={item?._id} sx={{ width: "30%", height: "10%" }}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingX: "1rem",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontSize="18px"
                      sx={{
                        textTransform: "capitalize",
                      }}
                    >
                      {item?.name}
                    </Typography>
                    <Box gap="0.5rem">
                      <Tooltip title="Edit Ticket Type">
                        <IconButton onClick={() => handleEdit(item)}>
                          <ModeEditOutlineOutlinedIcon
                            sx={{ fontSize: "25px", color: "#001A72" }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Ticket Type">
                        <IconButton onClick={() => handleDeleteOpen(item._id)}>
                          <DeleteOutline sx={{ color: "#D60000" }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Box>
        </>
      )}
    </>
  );
};

export default GetTicketTypes;
