import { alpha } from "@mui/material/styles";

const BoxStyle = (mode) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  mr: 1,
  mb: 2,
  borderRadius: 2,
  backgroundColor:
    mode === "create" ? alpha("#23239C", 0.05) : alpha("#AAAAAA", 0.05),
  border: "1px solid rgba(35, 35, 156, 0.1)",
});

export default BoxStyle;
