import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Box,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import SnackbarComponent from "app/components/SnackBar";
import { adminUserSettings } from "app/utils/api-urls/AdminAPiUrls";
import { instance } from "app/utils/axiosRequest";
import useDebounce from "app/utils/hooks/useDebounce";
import qs from "qs";
import { useState } from "react";
import AdminTab from "./AdminTabComponent";
import AgentTab from "./AgentTabComponent";
import SupervisorTab from "./SupervisorTabComponent";
import UserFormModal from "./UserPageComponents/UserFormModalComponent";

const UserSetting = () => {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  // given admin as default role since
  // tab value 0 is admin so it shows admin data by default
  const [filterData, setFilterData] = useState({
    role: "admin",
    extension: "",
  });

  const [snackbarData, setSnackbarData] = useState({
    open: false,
    severity: "error",
    message: "",
  });

  const {
    data,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery(["admin-user-setting", filterData], () =>
    instance.get(
      adminUserSettings.admin_user_settings + "?" + qs.stringify(filterData)
    )
  );

  const deleteUserMutation = useMutation(
    ["delete-user"],
    (userId) =>
      instance.delete(adminUserSettings.admin_user_api + `/${userId._id}`),
    {
      onSuccess: (response) => {
        setSnackbarData({
          open: true,
          severity: "success",
          message: "User Deleted!",
        });
        handleDeleteClose();
        refetch();
      },
      onError: () => {
        setSnackbarData({
          open: true,
          severity: "error",
          message: "Error Deleting User!",
        });
      },
    }
  );

  const { data: departmentData, refetch: deptFetch } = useQuery(
    ["admin-user-settings-department"],
    () =>
      instance.get(
        adminUserSettings.admin_user_department + "?" + qs.stringify()
      ),
    { enabled: false }
  );

  const { data: queueData, refetch: queueFetch } = useQuery(
    ["admin-user-settings-queues"],
    () =>
      instance.get(adminUserSettings.admin_user_queues + "?" + qs.stringify()),
    { enabled: false }
  );

  const { data: userData, refetch: userFetch } = useQuery(
    ["admin-user-setting"],
    () =>
      instance.get(
        adminUserSettings.admin_user_settings + "?" + qs.stringify()
      ),
    { enabled: false }
  );
  // defined roles based on the tab value
  const getRoleFromTab = (tabValue) => {
    if (tabValue === 0) return "admin";
    if (tabValue === 1) return "supervisor";
    if (tabValue === 2) return "agent";
    return "";
  };

  // to get the roles from the data like admin or supervisor or agent
  const roles = data?.data?.data?.map((item) => item.role) || [];

  // to filter the data that goes to the tab based on the roles
  const filteredData =
    data?.data?.data?.filter((item) => roles.includes(item.role)) || [];

  // assign departmentData into departmentNames
  const departmentName =
    departmentData?.data?.data?.map((department) => {
      return {
        id: department._id,
        name: department.name,
        number: department.number,
      };
    }) || [];

  // assign queue Data into queue names
  const queueNames =
    queueData?.data.map((queue) => {
      return {
        id: queue._id,
        name: queue.name,
        number: queue.number,
      };
    }) || [];

  // assign users with role agents into agentNames
  const agentNames =
    userData?.data?.data
      ?.filter((user) => user.role === "agent")
      ?.map((agent) => ({
        id: agent._id,
        name: `${agent.firstName} (${agent.extension})`,
        extension: agent.extension,
      })) || [];

  // assign users with role supervisors into supervisorNames
  const supervisorNames =
    userData?.data?.data
      ?.filter((user) => user.role === "supervisor")
      ?.map((supervisor) => ({
        id: supervisor._id,
        name: `${supervisor.firstName} (${supervisor.extension})`,
        extension: supervisor.extension,
      })) || [];

  const handleSnackbarClose = () => {
    setSnackbarData({ ...snackbarData, open: false });
  };

  const handleOpen = () => {
    setOpen(true);
    setEditData(null);
    userFetch();
    queueFetch();
    deptFetch();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = (data) => {
    setEditData(data);
    setOpen(true);
    userFetch();
    queueFetch();
    deptFetch();
  };

  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  //  filter the data based on roles when tabs change
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      role: getRoleFromTab(newValue),
    }));
    refetch(filterData);
  };

  // Define the delete function that uses the mutation
  const handleUserDelete = (userId) => {
    deleteUserMutation.mutate(userId);
  };

  // TODO
  const debouncedFilterData = useDebounce(filterData, 3000);

  const handleFilterChange = (name, value) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      [name]: value,
    }));
    refetch(filterData);

    // Debounce the state update before calling refetch
    // setTimeout(() => {
    //   refetch(debouncedFilterData);
    // }, 3000);
  };

  const handleSetSnackbarData = (newSnackbarData) => {
    setSnackbarData(newSnackbarData);
  };

  return (
    <>
      <FullpageLoader open={isLoading || isRefetching} />
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              flexGrow: 1,
              "& .MuiTabs-indicator": {
                backgroundColor: "#23239C",
                height: 4,
                borderRadius: 4,
              },
            }}
          >
            <Tab
              label="Admin"
              sx={{
                color: "#131313",
                "&:hover": { color: "grey" },
                "&.Mui-selected": { color: "#131313" },
              }}
            />
            <Tab
              label="Supervisor"
              sx={{
                color: "#131313",
                "&:hover": { color: "grey" },
                "&.Mui-selected": { color: "#131313" },
              }}
            />
            <Tab
              label="Agent"
              sx={{
                color: "#131313",
                "&:hover": { color: "grey" },
                "&.Mui-selected": { color: "#131313" },
              }}
            />
          </Tabs>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography>Add User</Typography>
            <Tooltip title="Add New User">
              <IconButton onClick={handleOpen}>
                <AddCircleOutlineIcon
                  sx={{
                    color: "#001A72",
                    fontSize: "30px",
                    fontWeight: "10",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        {open && (
          <UserFormModal
            open={open}
            handleClose={handleClose}
            handleOpen={handleOpen}
            mode={editData ? "edit" : "add"}
            data={editData}
            onEdit={handleEdit}
            departmentName={departmentName}
            queueNames={queueNames}
            agentNames={agentNames}
            supervisorNames={supervisorNames}
            refetch={refetch}
            snackbarData={snackbarData}
            handleSnackbarClose={handleSnackbarClose}
            setSnackbarData={handleSetSnackbarData}
          />
        )}
        {!isLoading && (
          <Box>
            {value === 0 && (
              <Box>
                <AdminTab
                  data={filteredData}
                  onEdit={handleEdit}
                  handleFilterChange={handleFilterChange}
                  filterData={filterData}
                  snackbarData={snackbarData}
                  handleSnackbarClose={handleSnackbarClose}
                  setSnackbarData={handleSetSnackbarData}
                />
              </Box>
            )}
            {value === 1 && (
              <Box>
                <SupervisorTab
                  data={filteredData}
                  onEdit={handleEdit}
                  onDelete={handleUserDelete}
                  handleFilterChange={handleFilterChange}
                  filterData={filterData}
                  snackbarData={snackbarData}
                  handleSnackbarClose={handleSnackbarClose}
                  setSnackbarData={handleSetSnackbarData}
                  handleDeleteOpen={handleDeleteOpen}
                  handleDeleteClose={handleDeleteClose}
                  openDelete={openDelete}
                  isLoading={isLoading}
                  deleteLoading={deleteUserMutation.isLoading}
                />
              </Box>
            )}
            {value === 2 && (
              <Box>
                <AgentTab
                  data={filteredData}
                  onEdit={handleEdit}
                  onDelete={handleUserDelete}
                  handleFilterChange={handleFilterChange}
                  filterData={filterData}
                  snackbarData={snackbarData}
                  handleSnackbarClose={handleSnackbarClose}
                  setSnackbarData={handleSetSnackbarData}
                  handleDeleteOpen={handleDeleteOpen}
                  handleDeleteClose={handleDeleteClose}
                  openDelete={openDelete}
                  isLoading={isLoading}
                  deleteLoading={deleteUserMutation.isLoading}
                />
              </Box>
            )}
          </Box>
        )}
        <SnackbarComponent
          open={snackbarData?.open}
          handleClose={handleSnackbarClose}
          data={snackbarData}
        />
      </Box>
    </>
  );
};

export default UserSetting;
