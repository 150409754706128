import { Box, Grid } from "@mui/material";
import { useState } from "react";
import CallOverviewComponent from "./CallSummary/CallOverview";
import CampaignsTableComponent from "./CallSummary/CampaignsTable";
import LiveCallsTableComponent from "./CallSummary/LiveCallsTable";
import CallBlastingTabs from "./CallBlastingTabComponent";

const CallBlasting = () => {
  const [selectedRow, setSelectedRow] = useState(null);
  const handleRowClick = (row) => {
    setSelectedRow(row);
  };
  const tableData = selectedRow ? selectedRow.row.tableData : [];
  const data = selectedRow ? selectedRow.row : [];

  return (
    <Box sx={{ flex: 1, mt: 2 }}>
      <Grid container spacing={"22px"}>
        <Grid item xs={12} md={12} lg={8} sm={12}>
          <Grid container direction="column" spacing={"22px"}>
            <Grid item>
              <CallOverviewComponent />
            </Grid>
            <Grid item>
              <CallBlastingTabs />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={4} sm={12}>
          <Grid container direction="column" spacing={"22px"}>
            <Grid item>
              <CampaignsTableComponent handleRowClick={handleRowClick} />
            </Grid>
            <Grid item>
              <LiveCallsTableComponent tableData={tableData} data={data} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CallBlasting;
