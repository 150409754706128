import React from "react";

const TextfieldStyle = {
  mr: 2,
  m: 1,
  display: "flex",
  borderRadius: 2,
  backgroundColor: "transparent",
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(35, 35, 156, 0.4)",
    "&:hover": {
      borderColor: "grey",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#23239C",
      color: "#23239C",
    },
  },
};

export default TextfieldStyle;
