import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import ChangePasswordModalComponent from "./ChangePasswordModalComponent";

const AdminUserInfoComponent = ({
  adminData,
  onEdit,
  changePassword,
  snackbarData,
  handleSnackbarClose,
  setSnackbarData,
}) => {
  const [openChangePassword, setOpenChangePassword] = useState(false);

  const handleEdit = () => {
    onEdit(adminData);
  };

  const handleChangePasswordOpen = () => {
    setOpenChangePassword(true);
  };

  const handleChangePasswordClose = () => {
    setOpenChangePassword(false);
  };

  return (
    <Box
      sx={{
        m: 2,
        display: "flex",
        justifyContent: "space-evenly",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ fontWeight: "medium", fontSize: 24, color: "#131313" }}
        >
          {adminData?.firstName} {adminData?.middleName} {adminData?.lastName}
        </Typography>
        <Box>
          <Tooltip title="Edit">
            <IconButton onClick={handleEdit}>
              <ModeEditOutlineOutlinedIcon sx={{ color: "#001A72" }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: 18,
          mt: 1,
          color: "#131313B3",
        }}
      >
        Extension No:
        <Typography
          sx={{ ml: 1, fontWeight: "medium", fontSize: 18, color: "#131313B3" }}
        >
          {adminData?.extension}
        </Typography>
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
          mt: 2,
        }}
      >
        <Tooltip title={adminData.phone} arrow>
          <Typography sx={{ color: "#23239C", fontSize: 16 }}>
            <PhoneInTalkOutlinedIcon sx={{ color: "#23239CAB", mr: 1 }} />
            {adminData?.phone}
          </Typography>
        </Tooltip>
        <Tooltip title={adminData.email} arrow>
          <Typography sx={{ color: "#23239C", fontSize: 16 }}>
            <EmailOutlinedIcon sx={{ color: "#23239CAB", mr: 1 }} />
            {adminData?.email}
          </Typography>
        </Tooltip>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
        }}
      >
        <Typography
          sx={{
            display: "flex",
            color: "#131313B3",
            fontSize: 15,
            alignItems: "baseline",
          }}
        >
          Created At:
          <Typography sx={{ color: "#131313B3", fontSize: 15, ml: 1 }}>
            {dayjs(adminData?.createdAt).format("YYYY-MM-DD HH:mm:ss")}
          </Typography>
        </Typography>
        <Tooltip title="Change Password">
          <Button
            variant="text"
            sx={{ padding: 0, alignItems: "center" }}
            onClick={handleChangePasswordOpen}
          >
            <LockOpenIcon sx={{ color: "#23239CAB", fontSize: 18 }} />
            <Typography
              sx={{ textTransform: "none", color: "#23239C", fontSize: 15 }}
            >
              Change Password
            </Typography>
          </Button>
        </Tooltip>
        {openChangePassword && (
          <ChangePasswordModalComponent
            open={openChangePassword}
            handleClose={handleChangePasswordClose}
            handleOpen={handleChangePasswordOpen}
            changePassword={changePassword}
            userId={adminData._id}
            snackbarData={snackbarData}
            handleSnackbarClose={handleSnackbarClose}
            setSnackbarData={setSnackbarData}
          />
        )}
      </Box>
    </Box>
  );
};

export default AdminUserInfoComponent;
