import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import CustomDataGrid from "app/components/Shared/DataGrid/CustomDataGrid";
import { adminCallBlasting } from "app/utils/api-urls/AdminAPiUrls";
import { instance } from "app/utils/axiosRequest";
import dayjs from "dayjs";

const CampaignsTableComponent = ({ handleRowClick }) => {
  const columns = [
    {
      field: "campaignName",
      headerName: "Campaign Name",
      flex: 1.5,
    },
    {
      field: "scheduleDate",
      headerName: "Created Date",
      flex: 1,
      renderCell: (params) =>
        dayjs(params?.row?.scheduleDate).format("YYYY-MM-DD"),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{
            color:
              params.value === "Running"
                ? "#4E9F3D"
                : params.value === "Stop"
                ? "#D60000"
                : "#EFCB63",
          }}
        >
          {params?.row?.status}
        </Typography>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        const _id = params?.row?._id;
        const status = params?.row?.status;
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {status === "Pause" || status === "Stop" ? (
              <Tooltip title="Running">
                <IconButton onClick={() => handleResume(_id)}>
                  <PlayCircleFilledWhiteOutlinedIcon
                    sx={{ color: "#001A72", fontSize: 20 }}
                  />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Pause">
                <IconButton onClick={() => handlePause(_id)}>
                  <PauseCircleOutlineIcon
                    sx={{ color: "#001A72", fontSize: 20 }}
                  />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="Stop">
              <IconButton onClick={() => handleStop(_id)}>
                <StopCircleOutlinedIcon
                  sx={{ color: "#D60000", fontSize: 20 }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const { data, isLoading, isRefetching, refetch } = useQuery(
    ["callBlasting-campaign"],
    () => instance.get(adminCallBlasting.admin_call_blasting_campaign_api)
  );

  const { mutate: campaignStop, isLoading: isLoadingStop } = useMutation(
    ["callBlasting-campaign-stop"],
    (id) =>
      instance.patch(
        adminCallBlasting.admin_call_blasting_campaign_call_stop + `/${id}`
      ),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const { mutate: campaignPause, isLoading: isLoadingPause } = useMutation(
    ["callBlasting-campaign-stop"],
    (id) =>
      instance.patch(
        adminCallBlasting.admin_call_blasting_campaign_call_pause + `/${id}`
      ),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const { mutate: campaignResume, isLoading: isLoadingPlay } = useMutation(
    ["callBlasting-campaign-stop"],
    (id) =>
      instance.patch(
        adminCallBlasting.admin_call_blasting_campaign_call_resume + `/${id}`
      ),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const handleResume = (_id) => {
    campaignResume(_id);
  };

  const handleStop = (_id) => {
    campaignStop(_id);
  };

  const handlePause = (_id) => {
    campaignPause(_id);
  };

  return (
    <>
      <FullpageLoader
        open={
          isLoading ||
          isRefetching ||
          isLoadingStop ||
          isLoadingPause ||
          isLoadingPlay
        }
      />
      <Card
        sx={{
          height: "60vh",
          // boxShadow: "4px 2px 24px rgba(35, 35, 156, 0.2)",
          borderRadius: "14px",
        }}
      >
        <CardContent>
          <Typography variant="h6" sx={{ ml: 2, py: 1 }}>
            Campaigns
          </Typography>
          <Box
            sx={{
              marginBottom: 2,
              ml: 2,
              mr: 2,
            }}
          >
            <CustomDataGrid
              rows={data?.data?.data || []}
              height={"45vh"}
              columns={columns}
              getRowId={(row) => row._id}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default CampaignsTableComponent;
