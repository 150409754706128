import DownloadIcon from "@mui/icons-material/Download";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import CustomDataGrid from "app/components/Shared/DataGrid/CustomDataGrid";
import DatePicker from "app/components/Shared/DatePicker/DatePicker.component";
import SelectComponent from "app/components/Shared/Select/Select.component";
import { adminCallBlasting } from "app/utils/api-urls/AdminAPiUrls";
import { instance } from "app/utils/axiosRequest";
import dayjs from "dayjs";
import qs from "qs";
import { lazy, useState } from "react";
import ChartComponent from "./CustomChart";

const SnackbarComponent = lazy(() => import("app/components/SnackBar"));
const ViewReportModal = lazy(() => import("./ReportPageViewReport"));

const ReportPage = () => {
  const [viewReport, setViewReport] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [snackbarData, setSnackbarData] = useState({
    open: false,
    severity: "error",
    message: "",
  });

  const [filterData, setFilterData] = useState({
    startDate: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  });

  const columns = [
    { field: "campaignName", headerName: "Campaign Name", flex: 1 },
    { field: "congestionCount", headerName: "Congestion Calls", flex: 1 },
    { field: "successfulCount", headerName: "Successful Calls", flex: 1 },
    { field: "missedCount", headerName: "Missed Calls", flex: 1 },
    { field: "unsuccessfulCount", headerName: "Failed Calls", flex: 1 },
    { field: "totalCallAttempts", headerName: "Attempt Calls", flex: 1 },
    { field: "avgDuration", headerName: "Average Duration", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tooltip title="View">
            <IconButton onClick={() => handleViewReport(params?.row)}>
              <VisibilityOutlinedIcon sx={{ color: "#001A72", fontSize: 20 }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download">
            <IconButton onClick={() => handleDownload(params?.row?.campaignID)}>
              <DownloadIcon sx={{ color: "#001A72", fontSize: 20 }} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const chartData = {
    categories: ["Sun", "Mon", "Tue", "Wed", "Thurs", "Fri", "Sat"],

    colors: ["#008000", "#D60000", "#FFD700"],

    series: [
      {
        name: "Successful Calls",
        data: [10, 41, 35, 51, 49, 91, 148],
        color: "#008000",
      },
      {
        name: "Missed Calls",
        data: [20, 35, 45, 65, 25, 75, 55],
        color: "#D60000",
      },
      {
        name: "Failed Calls",
        data: [5, 35, 45, 55, 65, 15, 25],
        color: "#FFD700",
      },
    ],
  };

  const options = [
    { value: "thisWeek", label: "This Week" },
    { value: "thisMonth", label: "This Month" },
    { value: "thisYear", label: "This Year" },
  ];

  const { data, isLoading, isRefetching } = useQuery(
    ["reports", filterData],
    () =>
      instance.get(
        adminCallBlasting.admin_call_blasting_campaign_report_log +
          "?" +
          qs.stringify(filterData)
      )
  );

  const { data: campaignLogs, isLoading: logsIsLoading } = useQuery(
    ["campaignLogById", selectedRowData],
    () =>
      instance.get(
        adminCallBlasting.admin_call_blasting_campaign_report_log +
          `/${selectedRowData.campaignID}`
      )
  );

  const { mutate: downloadFile, isLoading: downloading } = useMutation(
    (selectedFile) =>
      instance.get(
        adminCallBlasting.admin_call_blasting_callBlasting_report_download +
          `/${selectedFile.id}`,
        { responseType: "blob" }
      ),
    {
      onSuccess: (response, selectedFile) => {
        const contentType = response.data["type"];
        const fileExtension = contentType.split("/")[1];
        const fileName = `CampaignLogReport.${fileExtension}`;
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: contentType })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        setSnackbarData({
          open: true,
          severity: "success",
          message: "Download Success!",
        });
      },
      onError: (error) => {
        setSnackbarData({
          open: true,
          severity: "error",
          message: "Download Failed!",
        });
      },
    }
  );

  const handleDownload = (campaignID) => {
    downloadFile({ id: campaignID });
  };

  const handleFromDate = (value) => {
    setFilterData({
      ...filterData,
      startDate: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleToDate = (value) => {
    setFilterData({
      ...filterData,
      endDate: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleViewReport = (row) => {
    setSelectedRowData(row);
    setViewReport(true);
  };

  const handleClose = () => {
    setViewReport(false);
  };

  const handleThisWeek = () => {
    setFilterData({
      from: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
      to: dayjs().format("YYYY-MM-DD"),
    });
  };

  const handleThisMonth = () => {
    setFilterData({
      from: dayjs().startOf("month").format("YYYY-MM-DD"),
      to: dayjs().format("YYYY-MM-DD"),
    });
  };

  const handleThisYear = () => {
    setFilterData({
      from: dayjs().startOf("year").format("YYYY-MM-DD"),
      to: dayjs().format("YYYY-MM-DD"),
    });
  };

  const handleTimeFrameChange = (value) => {
    if (value === "thisWeek") handleThisWeek();
    else if (value === "thisMonth") handleThisMonth();
    else if (value === "thisYear") handleThisYear();
  };

  const handleSnackbarClose = () => {
    setSnackbarData({ ...snackbarData, open: false });
  };

  return (
    <>
      <FullpageLoader open={isLoading || downloading || isRefetching} />
      {viewReport && (
        <ViewReportModal
          selectedRowData={campaignLogs?.data}
          handleClose={handleClose}
          viewReport={viewReport}
          isLoading={logsIsLoading}
          handleDownload={handleDownload}
        />
      )}
      {snackbarData.open && (
        <SnackbarComponent
          data={snackbarData}
          open={snackbarData.open}
          handleClose={handleSnackbarClose}
        />
      )}
      <Box
        sx={{
          height: "100vh",
          overflowY: "hidden",
          overflowX: "hidden",
          marginBottom: 2,
          px: 2,
        }}
      >
        <Box sx={{ mb: 1, mt: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
            }}
          >
            <Typography variant="h6" sx={{ ml: 2 }}>
              Successful/ Missed/ Failed Calls
            </Typography>
            <Box sx={{ display: "flex", width: "10vw", mt: 0 }}>
              <SelectComponent
                options={options}
                label={"Time Frame"}
                handleChange={handleTimeFrameChange}
                name={"timeFrame"}
                required
              />
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="30vh"
          >
            <ChartComponent
              series={chartData.series}
              colors={chartData.series.map((item) => item.color)}
              categories={chartData.categories}
            />
          </Box>
        </Box>
        <Box>
          <Grid container direction="row">
            <Box
              display={"flex"}
              flexDirection="row"
              justifyContent="space-between"
              alignItems="end"
              width="100%"
              marginTop={1}
            >
              <Typography variant="h6">Call Blasting Report</Typography>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                sx={{ width: 350 }}
              >
                <Box sx={{ m: 1 }}>
                  <DatePicker
                    label={"From"}
                    value={filterData?.startDate}
                    handleChange={handleFromDate}
                    name="startDate"
                  />
                </Box>
                <Box sx={{ m: 1 }}>
                  <DatePicker
                    label={"To"}
                    value={filterData?.endDate}
                    handleChange={handleToDate}
                    name="endDate"
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Box sx={{ mb: 5 }}>
            {data?.data?.data ? (
              <CustomDataGrid
                rows={data?.data?.data}
                columns={columns}
                getRowId={(row) => row?.campaignID}
                height={"35vh"}
              />
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="30vh"
              >
                <Typography variant="body2">No Report Found</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ReportPage;
