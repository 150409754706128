import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import AutocompleteComponent from "app/components/Shared/MultiSelectComponent/AutocompleteComponent";
import SnackbarComponent from "app/components/SnackBar";
import { adminUserSettings } from "app/utils/api-urls/AdminAPiUrls";
import { instance } from "app/utils/axiosRequest";
import { useState } from "react";
import CustomSelectComponent from "./CustomSelectComponent";
import TextFormField from "./TextFieldComponent";

const UserFormModal = ({
  open,
  handleClose,
  handleOpen,
  mode,
  data,
  onEdit,
  departmentName,
  queueNames,
  agentNames,
  supervisorNames,
  refetch,
  snackbarData,
  handleSnackbarClose,
  setSnackbarData,
}) => {
  // Initial form data structure
  const initialFormData = {
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    extension: "",
    password: "",
    confirmPassword: "",
    role: "admin",
    departments: [],
    queues: [],
    supervisors: [],
    agents: [],
  };

  // State for managing form data
  const [formData, setFormData] = useState(
    mode === "edit" ? { ...data, role: data.role } : initialFormData
  );

  const [passwordVisibility, setPasswordVisibility] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });

  const [selectedRole, setSelectedRole] = useState(
    mode !== "edit" ? "admin" : data.role
  );

  // for password validation
  const [touchedFields, setTouchedFields] = useState({
    password: false,
    confirmPassword: false,
  });

  const addUserMutation = useMutation(
    ["post-new-user"],
    (newUser) => instance.post(adminUserSettings.admin_user_api, newUser),
    {
      onSuccess: (response) => {
        setSnackbarData({
          open: true,
          severity: "success",
          message: "User Created successfully!",
        });
        handleClose();
        resetForm();
        refetch();
      },
      onError: () => {
        setSnackbarData({
          open: true,
          severity: "error",
          message: "Error Creating User!",
        });
      },
    }
  );

  // api for patch user
  const editUserMutation = useMutation(
    ["edit-user"],
    (userData) =>
      instance.patch(
        adminUserSettings.admin_user_api + `/${userData._id}`,
        userData
      ),
    {
      onSuccess: () => {
        handleClose();
        resetForm();
        refetch();
        setSnackbarData({
          open: true,
          severity: "success",
          message: "Updated successfully",
        });
      },
      onError: () => {
        setSnackbarData({
          open: true,
          severity: "error",
          message: "Error Updating User",
        });
      },
    }
  );

  // toggles the visibility of password and confirm password fields
  const handleClickShowPassword = (field) => {
    setPasswordVisibility({
      ...passwordVisibility,
      [field]: !passwordVisibility[field],
    });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // single select handler for department
  const handleSelectChangeDepartment = (selected) => {
    setFormData({ ...formData, departments: [selected] });
  };

  const resetForm = () => {
    setFormData(initialFormData);
  };

  const handleModalClose = () => {
    handleClose();
    resetForm();
  };

  const handleRoleChange = (e) => {
    const selectedRole = e.target.value;
    setFormData({ ...formData, role: e.target.value });
    setSelectedRole(selectedRole);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Filter out empty fields and arrays from formData
    const filteredFormData = { ...formData };
    Object.keys(filteredFormData).forEach((key) => {
      if (
        (Array.isArray(filteredFormData[key]) &&
          filteredFormData[key].length === 0) ||
        (typeof filteredFormData[key] === "string" &&
          filteredFormData[key].trim() === "")
      ) {
        delete filteredFormData[key];
      }
    });

    // TODO: Handle middleName separately if needed
    if (addUserMutation) {
      addUserMutation.mutate(filteredFormData);
    } else {
      alert("Error creating user");
    }
  };

  const handleEdit = () => {
    // Filter out empty fields and arrays from formData
    const filteredFormData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => {
        if (Array.isArray(value)) {
          // Exclude empty arrays
          return value.length > 0;
        } else if (typeof value === "string") {
          // Exclude empty strings
          return value.trim() !== "";
        } else {
          // Include non-array and non-string fields
          return true;
        }
      })
    );

    // Destructure _id from filteredFormData
    const { _id, ...userData } = filteredFormData;

    // Compare edited data with initial data
    const editedData = Object.fromEntries(
      Object.entries(userData).filter(([key, value]) => {
        return data[key] !== value;
      })
    );

    // Call editUserMutation with editedData
    editUserMutation.mutate({ _id, ...editedData });
  };

  // Mark a field as touched (focused) for validation.
  const handleFieldFocus = (fieldName) => {
    setTouchedFields({
      ...touchedFields,
      [fieldName]: true,
    });
  };
  // Validate a field based on specific criteria and return an error message if validation fails.
  const validateField = (fieldName) => {
    const value = formData[fieldName];
    const hasValue = value.trim() !== "";
    const hasValidLength = value.length >= 8;
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSpecialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
      value
    );

    if (!touchedFields[fieldName] || !hasValue) return "";
    if (!touchedFields[fieldName] && !hasValue) return "Password is required";
    if (!hasValidLength) return "Password should be at least 8 characters long";
    if (!hasUpperCase)
      return "Password should contain at least one uppercase letter";
    if (!hasLowerCase)
      return "Password should contain at least one lowercase letter";
    if (!hasNumber) return "Password should contain at least one number";
    if (!hasSpecialCharacter)
      return "Password should contain at least one special character";
    if (fieldName === "confirmPassword" && value !== formData.password)
      return "Passwords do not match";
    return "";
  };

  const handleAutocompleteSelect = (fieldName, ids) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: ids,
    }));
  };
  return (
    <>
      <FullpageLoader open={addUserMutation.isLoading} />
      <Modal open={handleOpen} onClose={handleModalClose}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            width: "60rem",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: "1 4px 6px rgba(35, 35, 156, 0.2)",
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 2,
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            mb={2}
          >
            <Typography variant="h6" align="left" gutterBottom ml={1}>
              {mode !== "edit" ? "Create New User" : "Edit User"}
            </Typography>
            <IconButton onClick={handleModalClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <RadioGroup
              value={selectedRole}
              onChange={handleRoleChange}
              sx={{ display: "flex", flexDirection: "row" }}
            >
              {[
                { label: "Admin", value: "admin" },
                { label: "Supervisor", value: "supervisor" },
                { label: "Agent", value: "agent" },
              ].map((option, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: alpha("#23239C", 0.9),
                        },
                      }}
                    />
                  }
                  label={option.label}
                  value={option.value}
                  disabled={mode === "edit" && data.role !== option.value}
                />
              ))}
            </RadioGroup>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextFormField
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                type={"text"}
                required={true}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFormField
                label="Middle Name"
                name="middleName"
                value={formData.middleName}
                onChange={handleChange}
                type={"text"}
                required={false}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFormField
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                type={"text"}
                required={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextFormField
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                type={"text"}
                required={true}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFormField
                label="Phone Number"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                type={"text"}
                required={true}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFormField
                label="Extension No."
                name="extension"
                value={formData.extension}
                onChange={handleChange}
                type={"text"}
                required={true}
              />
            </Grid>
          </Grid>
          {selectedRole === "supervisor" && (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <CustomSelectComponent
                  label="Select Department"
                  name="departments"
                  initialOptions={departmentName.map((name) => ({
                    id: name.id,
                    label: name.name,
                    number: name.number,
                  }))}
                  // only send id when editing
                  value={
                    mode !== "edit"
                      ? formData?.departments
                      : formData?.departments?._id
                  }
                  handleChange={handleSelectChangeDepartment}
                  required={false}
                />
              </Grid>
              <Grid item xs={4}>
                <AutocompleteComponent
                  options={agentNames}
                  onSelect={handleAutocompleteSelect}
                  fieldName="agents"
                  label={"Select Agents"}
                  value={
                    mode === "edit"
                      ? formData.agents.map((agents) => agents._id)
                      : []
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <AutocompleteComponent
                  options={queueNames}
                  onSelect={handleAutocompleteSelect}
                  fieldName="queues"
                  label={"Select Queues"}
                  value={
                    mode === "edit"
                      ? formData.queues.map((queue) => queue._id)
                      : []
                  }
                />
              </Grid>
            </Grid>
          )}
          {selectedRole === "agent" && (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <CustomSelectComponent
                  label="Select Department"
                  name="departmentName"
                  initialOptions={departmentName.map((name) => ({
                    id: name.id,
                    label: name.name,
                    number: name.number,
                  }))}
                  // only send id when editing
                  value={
                    mode !== "edit"
                      ? formData.departments
                      : formData?.departments?._id
                  }
                  handleChange={handleSelectChangeDepartment}
                  required={false}
                />
              </Grid>
              <Grid item xs={4}>
                <AutocompleteComponent
                  options={supervisorNames}
                  onSelect={handleAutocompleteSelect}
                  fieldName="supervisors"
                  label={"Select Supervisors"}
                  value={
                    mode === "edit"
                      ? formData.supervisors.map((sup) => sup._id)
                      : []
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <AutocompleteComponent
                  options={queueNames}
                  onSelect={handleAutocompleteSelect}
                  fieldName="queues"
                  label={"Select Queues"}
                  value={
                    mode === "edit"
                      ? formData.queues.map((queue) => queue._id)
                      : []
                  }
                />
              </Grid>
            </Grid>
          )}
          {/* hide these fields in edit mode */}
          {mode !== "edit" && (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label="Password"
                  fullWidth
                  variant="outlined"
                  name="password"
                  type={passwordVisibility.showPassword ? "text" : "password"}
                  onFocus={() => handleFieldFocus("password")}
                  helperText={
                    <Typography sx={{ color: "red", fontSize: "14px" }}>
                      {validateField("password")}
                    </Typography>
                  }
                  autoComplete="off"
                  sx={{
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#23239C",
                        "&:focus-within": {
                          borderColor: "#23239C",
                          color: "#23239C",
                        },
                        "&:hover": {
                          borderColor: "grey",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#23239C",
                          color: "#23239C",
                        },
                      },
                    "& .MuiFormLabel-root": {
                      color: "#23239C",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        onClick={() => handleClickShowPassword("showPassword")}
                        position="end"
                      >
                        {passwordVisibility.showPassword ? (
                          <VisibilityOutlinedIcon
                            sx={{ cursor: "pointer", color: "#23239C" }}
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            sx={{ cursor: "pointer", color: "#23239C" }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  value={formData.password}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Confirm Password"
                  fullWidth
                  variant="outlined"
                  name="confirmPassword"
                  type={
                    passwordVisibility.showConfirmPassword ? "text" : "password"
                  }
                  onFocus={() => handleFieldFocus("confirmPassword")}
                  helperText={
                    <Typography sx={{ color: "red", fontSize: "14px" }}>
                      {validateField("confirmPassword")}
                    </Typography>
                  }
                  sx={{
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#23239C",
                        "&:focus-within": {
                          borderColor: "#23239C",
                          color: "#23239C",
                        },
                        "&:hover": {
                          borderColor: "grey",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#23239C",
                          color: "#23239C",
                        },
                      },
                    "& .MuiFormLabel-root": {
                      color: "#23239C",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        onClick={() =>
                          handleClickShowPassword("showConfirmPassword")
                        }
                        position="end"
                      >
                        {passwordVisibility.showConfirmPassword ? (
                          <VisibilityOutlinedIcon
                            sx={{ cursor: "pointer", color: "#23239C" }}
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            sx={{ cursor: "pointer", color: "#23239C" }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          )}
          {mode !== "edit" ? (
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={addUserMutation.isLoading}
              sx={{
                display: "flex",
                width: 80,
                justifyItems: "end",
                alignSelf: "flex-end",
                backgroundColor: "#23239C",
                "&:hover": {
                  backgroundColor: alpha("#23239C", 0.85),
                },
              }}
            >
              {addUserMutation.isLoading ? (
                <CircularProgress size={18} color="inherit" />
              ) : (
                "Save"
              )}
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={handleEdit}
              disabled={editUserMutation.isLoading}
              sx={{
                display: "flex",
                width: 80,
                justifyItems: "end",
                alignSelf: "flex-end",
                backgroundColor: "#23239C",
                "&:hover": {
                  backgroundColor: alpha("#23239C", 0.85),
                },
              }}
            >
              {editUserMutation.isLoading ? (
                <CircularProgress size={18} color="inherit" />
              ) : (
                "Update"
              )}
            </Button>
          )}
        </Box>
      </Modal>
      <SnackbarComponent
        open={snackbarData.open}
        handleClose={handleSnackbarClose}
        data={snackbarData}
      />
    </>
  );
};

export default UserFormModal;
