import CallBlasting from "app/pages/CallBlasting";

const callBlastingRoutes = [
  {
    path: "/app/call-blasting",
    shortPath: "/call-blasting",
    name: "Call Blasting",
    icon: "mdi mdi-gesture-tap-button",
    component: CallBlasting,
  },
];

export default callBlastingRoutes;
