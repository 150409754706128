//Dashboard-Data
export const agentDashboardStats = {
  dashboard_stats: "agent/stats/agent",
};

//Ticket-list

export const ticketList = {
  ticket_list: "/agent/report/ticket",
  assigned_to_me: "/agent/report/assigned-ticket",
};

//Call-Report

export const CallReport = {
  call_report: "/agent/report/call",
  break_count: "extension/break-log-report",
};

//BreakButton

export const breakButton = {
  extension_status: "agent/extension-status",
  extension_pause: "agent/break",
};

///for agent category
export const agentcategoryList = {
  agent_category_list: "agent/issue",
};
///top 5 call history

export const topfivetickets = {
  top_five_tickets: "agent/tickets/phone",
};

//to update ticet status,remarks

export const updateTicketStatus = {
  update_ticket_status: "agent/solve-ticket",
};

//for chitchat tickets

export const chatticketsapi = {
  chat_tickets_api: "admin/chatTickets",
};

export const patchTicketStatus = {
  patch_ticket_status: "admin/ticketStatus",
};

//to listen recording for ticket
export const agentlistenRecording = {
  agent_listen_recording: "agent/recording/ticket",
};

//to listen recording of voice mail

export const agentlistenVoicemail = {
  agent_voicemail_recording: "agent/recording/voicemail",
};

export const agentpickTicketapi = {
  agent_pickedup_ticket_api: "agent/ticket/pick-ticket",
};

export const agentGetTemplate = {
  agent_get_template: "agent/template",
};

export const agentBreakCategory = {
  agent_break_category: "agent/breaks",
};

//version 2 ticket
export const agentTicketV2 = {
  agent_ticket_v2: "agent/v2/report/ticket",
};
export const getAllDepartmentAgent = {
  agent_get_all_department: "agent/v2/department",
};
export const createTicketAgent = {
  agent_create_ticket: "agent/v2/ticket",
};

export const addCommentTicketPage = {
  agent_add_comment: "agent/v2/ticket/comment",
};

export const getTicketById = {
  agentget_ticket_by_id: "agent/v2/ticket",
};

export const agentGetStatusTypes = {
  agent_get_status_types: "agent/v2/status-types",
};

export const agentGetTicketByPhone = {
  agent_get_ticket_by_phone: "agent/v2/ticket/phone",
};

export const agentgetAllticketType = {
  agent_get_all_ticket_type: "agent/v2/ticket/types",
};

export const agentPickTicketv2 = {
  agent_pick_ticket: "agent/v2/pick-ticket",
};

export const agentGetallNotifications = {
  agent_get_all_notifications: "agent/push-notifications",
};

export const agentReadNotifications = {
  agent_read_notification: "agent/read-notifications",
};

//
export const agentGetallUser = {
  agent_get_all_user: "agent/users",
};

export const agentGetallQueue = {
  agent_get_all_user: "agent/queues",
};

export const agentAssignTicket = {
  agent_assigned_ticket: "agent/assign-ticket",
};

export const agentAssignTicketDepartment = {
  agent_assigned_ticket_department: "agent/assign-ticket-department",
};

export const agentBulkTickets = {
  agent_bulk_tickets: "agent/bulk-ticket",
};

export const agentGetUserDetails = {
  agent_get_user_details: "agent/user-profile",
};

export const agentAbandonedCallReport={
  agent_abandoned_call_report_get: "agent/queue-logs/abandoned",
  agent_abandoned_call_report_patch: "agent/queue-log",
}
