import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import {
  PlayArrow,
  Chat,
  ChatBubbleOutline,
  Comment,
  Info,
} from "@mui/icons-material";
import TruncatedTextWithTooltip from "app/components/Shared/TruncatedText/TruncatedText.Component";
import UpdateActionPage from "./UpdateActionPage";
import CommentAction from "./CommentAction";
import UpdatedRemarks from "./updatedRemarks";

const DuringCalls = ({
  duringCallValue,
  handleSelectIssueCategory,
  handleSelectSubissueCategory,
  handleSelectQueue,
  handleSelectAgent,
  handleRemarksChange,
  allTicketData,
  handleStatusChange,
  statusOptions,
  handleCommentChange,
  handleUpdateRemarksChange,
  handleCreateTicket,
  handleUpdateTicket,
  handleCommentUpdate,
}) => {
  const [visibleCommentsTicketId, setVisibleCommentsTicketId] = useState(null);

  const rootOptions = [
    { id: 1, label: "Root 1", value: "root1" },
    { id: 2, label: "Root 2", value: "root2" },
    { id: 3, label: "Root 3", value: "root3" },
  ];

  const resolutionOptions = [
    { id: 1, label: "Resolution 1", value: "resolution1" },
    { id: 2, label: "Resolution 2", value: "resolution2" },
  ];

  const isActionAvailable = (action) => allTicketData?.action?.includes(action);
  const hasTicketLogs = allTicketData?.ticketLogs?.length > 0;
  const toggleCommentsVisibility = (ticketId) => {
    if (visibleCommentsTicketId === ticketId) {
      setVisibleCommentsTicketId(null);
    } else {
      setVisibleCommentsTicketId(ticketId);
    }
  };

  const [root, setRoot] = useState("");
  const [resolution, setResolution] = useState("");

  const handleRootChange = (event) => {
    setRoot(event.target.value);
    setResolution("");
  };

  const handleResolutionChange = (event) => {
    setResolution(event.target.value);
  };

  const [currentMode, setCurrentMode] = useState("default");

  useEffect(() => {
    if (
      allTicketData?.action?.includes("update") &&
      !allTicketData?.action?.includes("view")
    ) {
      setCurrentMode("update");
    } else if (
      !allTicketData?.action?.includes("update") &&
      allTicketData?.action?.includes("view")
    ) {
      setCurrentMode("comment");
    } else if (
      allTicketData?.action?.includes("update") &&
      allTicketData?.action?.includes("view")
    ) {
      setCurrentMode("update");
    } else {
      setCurrentMode("default");
    }
  }, [allTicketData?.action]);

  const handleAction = () => {
    if (currentMode === "default") {
      handleCreateTicket();
    } else if (currentMode === "update") {
      handleUpdateTicket();
    } else if (currentMode === "comment") {
      handleCommentUpdate();
    }
  };

  const toggleMode = () => {
    if (
      allTicketData?.action?.includes("update") &&
      allTicketData?.action?.includes("view")
    ) {
      setCurrentMode(currentMode === "update" ? "comment" : "update");
    }
  };

  const [isCommentMode, setIsCommentMode] = useState(false);

  return (
    <>
      <Card
        sx={{
          boxShadow: "4px 2px 24px 0px #23239C33",
          borderRadius: "14px",
          mt: 2,
        }}
      >
        <CardContent>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Typography variant="h6" component="div">
              Ticket Console
            </Typography>
            <Tooltip title="All fields are required">
              <IconButton size="small">
                <Info fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box display="flex" mt="1em" flexDirection="column" gap="1rem">
            <FormControl fullWidth size="medium">
              <InputLabel id="Issue Category">Issue Category *</InputLabel>
              <Select
                labelId="Issue Category"
                id="Issue Category"
                label="Issue Category *"
                required
                value={duringCallValue.selectedIssueCategory || ""}
                onChange={handleSelectIssueCategory}
                sx={{
                  borderRadius: "12px",
                  backgroundColor: "#F5F5F5",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "&:hover": {
                    backgroundColor: "#E0E0E0",
                  },
                  "&.Mui-focused": {
                    backgroundColor: "#F5F5F5",
                  },
                }}
              >
                {duringCallValue?.issueCategories?.map((option) => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth size="medium">
              <InputLabel id="Sub Issue Category">
                Sub Issue Category *
              </InputLabel>
              <Select
                labelId="Sub Issue Category"
                id="Sub Issue Category"
                label="Sub Issue Category"
                required
                value={duringCallValue?.selectedSubIssueCategory || ""}
                onChange={handleSelectSubissueCategory}
                sx={{
                  borderRadius: "12px",
                  backgroundColor: "#F5F5F5",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "&:hover": {
                    backgroundColor: "#E0E0E0",
                  },
                  "&.Mui-focused": {
                    backgroundColor: "#F5F5F5",
                  },
                }}
              >
                {duringCallValue?.subIssueCategories?.map((option) => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              size="medium"
              value={duringCallValue.remarks}
              label="Remarks"
              multiline
              required
              maxRows={1}
              name="remarks"
              onChange={handleRemarksChange}
              fullWidth
              InputProps={{
                sx: {
                  borderRadius: "12px",
                  backgroundColor: "#F5F5F5",
                },
                disableUnderline: true,
              }}
            />
            {/* ticket logs  */}
            {hasTicketLogs &&
              allTicketData?.ticketLogs?.map((item) => (
                <>
                  <React.Fragment key={item.id}>
                    {/* Ticket Log */}
                    <Box
                      key={item.id}
                      sx={{
                        padding: "10px",
                        backgroundColor: "#F5F5F5",
                        borderRadius: "12px",
                        display: "flex",
                        flexDirection: "column",
                        gap: 1.5,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          gap="10px"
                        >
                          <Typography variant="body1" color="#555555">
                            Remarks:
                          </Typography>
                          <TruncatedTextWithTooltip
                            text={item.remarks}
                            width="80%"
                          />
                        </Box>

                        <IconButton
                          aria-label="play"
                          size="small"
                          sx={{
                            size: "small",
                            color: "#23239C",
                            backgroundColor: "#DDDDDD",
                          }}
                        >
                          <PlayArrow fontSize="14px" />
                        </IconButton>
                      </Box>
                      {/* ticket logs section */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {/* for assigned status */}

                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          gap="5px"
                        >
                          <Typography variant="body1" color="#555555">
                            {item?.statusId?.name}
                            {""}
                          </Typography>
                          {item?.statusId?.name === "assigned" && (
                            <Typography
                              variant="body2"
                              sx={{ color: "#555555" }}
                            >
                              {item?.assignedTo?.firstName} (
                              {item.assignedDepartment?.name})
                            </Typography>
                          )}
                        </Box>

                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          gap="10px"
                        >
                          <Typography variant="body2" sx={{ color: "#555555" }}>
                            {item.userId?.firstName} ({item.userId?.extension})
                          </Typography>
                          <Typography variant="body2" sx={{ color: "#555555" }}>
                            {dayjs(item.createdAt).format("YYYY/MM/DD")} |{" "}
                          </Typography>
                          <Typography variant="body2" sx={{ color: "#555555" }}>
                            {dayjs(item.createdAt).format("HH:mm:ss")}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    {item?.comments?.length > 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          aria-label="comments"
                          size="small"
                          sx={{ color: "#23239C" }}
                          onClick={() => toggleCommentsVisibility(item._id)}
                        >
                          <Chat />
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#23239C",
                              display: "inline",
                              ml: 0.5,
                            }}
                          >
                            {item.comments.length} comments
                          </Typography>
                        </IconButton>
                      </Box>
                    )}
                    {/* for comment Section */}
                    {visibleCommentsTicketId === item._id && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            padding: "15px",
                            backgroundColor: "#F5F5F5",
                            borderRadius: "12px",
                            width: "80%",
                            display: "flex",
                            flexDirection: "column",
                            gap: ".5rem",
                          }}
                        >
                          {item?.comments?.map((comment) => (
                            <React.Fragment key={comment.id}>
                              <TruncatedTextWithTooltip
                                text={comment?.comment}
                                width="90%"
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  gap="10px"
                                >
                                  <Typography
                                    variant="body2"
                                    sx={{ color: "#555555" }}
                                  >
                                    Commented by :
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    fontWeight="bold"
                                    sx={{ color: "#555555" }}
                                  >
                                    {comment?.commentedBy?.firstName}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{ color: "#555555" }}
                                  >
                                    {dayjs(comment?.createdAt).format(
                                      "YYYY/MM/DD"
                                    )}{" "}
                                    |{" "}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{ color: "#555555" }}
                                  >
                                    {dayjs(comment?.createdAt).format(
                                      "HH:mm:ss"
                                    )}
                                  </Typography>
                                </Box>
                              </Box>
                              <Divider margin />
                            </React.Fragment>
                          ))}
                        </Box>
                      </Box>
                    )}
                  </React.Fragment>
                </>
              ))}
            {allTicketData?.action?.includes("update") &&
              allTicketData?.action?.includes("view") &&
              !isCommentMode && (
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  marginBottom=".5em"
                >
                  {allTicketData?.action?.includes("update") &&
                    allTicketData?.action?.includes("view") &&
                    !isCommentMode && (
                      <Button
                        startIcon={<ChatBubbleOutline />}
                        onClick={() => {
                          setIsCommentMode(true);
                          if (currentMode !== "comment") {
                            setCurrentMode("comment");
                          }
                        }}
                        sx={{ textTransform: "none", marginRight: "20px" }}
                      >
                        Click to add comment
                      </Button>
                    )}
                </Box>
              )}
            {currentMode === "update" && (
              <>
                <UpdatedRemarks
                  duringCallValue={duringCallValue}
                  handleUpdateRemarksChange={handleUpdateRemarksChange}
                />
                <UpdateActionPage
                  duringCallValue={duringCallValue}
                  handleSelectIssueCategory={handleSelectIssueCategory}
                  handleSelectSubissueCategory={handleSelectSubissueCategory}
                  handleSelectQueue={handleSelectQueue}
                  handleSelectAgent={handleSelectAgent}
                  handleRemarksChange={handleRemarksChange}
                  handleStatusChange={handleStatusChange}
                  statusOptions={statusOptions}
                  handleUpdateRemarksChange={handleUpdateRemarksChange}
                  hasTicketLogs={hasTicketLogs}
                />
              </>
            )}
            {currentMode === "comment" && (
              <CommentAction
                duringCallValue={duringCallValue}
                handleCommentChange={handleCommentChange}
              />
            )}
            {currentMode === "default" && (
              <UpdateActionPage
                duringCallValue={duringCallValue}
                handleSelectIssueCategory={handleSelectIssueCategory}
                handleSelectSubissueCategory={handleSelectSubissueCategory}
                handleSelectQueue={handleSelectQueue}
                handleSelectAgent={handleSelectAgent}
                handleRemarksChange={handleRemarksChange}
                handleStatusChange={handleStatusChange}
                statusOptions={statusOptions}
                handleUpdateRemarksChange={handleUpdateRemarksChange}
              />
            )}

            <Box display="flex" marginBottom=".5em" justifyContent="flex-end">
              {isCommentMode && (
                <Button
                  onClick={() => {
                    setIsCommentMode(false);
                    if (currentMode === "comment") {
                      toggleMode();
                    }
                  }}
                  sx={{
                    minWidth: "px",
                    borderRadius: "12px",
                    textTransform: "none",
                    marginRight: "20px",
                    height: "50px",
                    padding: "20px 20px",
                    fontSize: "1rem",
                    borderColor: "#D60000",
                    color: "#D60000",
                    "&:hover": {
                      borderColor: "#D60000",
                      backgroundColor: "rgba(214, 0, 0, 0.04)",
                    },
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
              )}
              <Button
                onClick={handleAction}
                sx={{
                  minWidth: "px",
                  borderRadius: "12px",
                  textTransform: "none",
                  height: "50px",
                  padding: "20px 20px",
                  fontSize: "1rem",
                  backgroundColor: "#23239C",
                  "&:hover": {
                    backgroundColor: "#1b296d",
                  },
                }}
                variant="contained"
              >
                {currentMode === "update"
                  ? "Update Ticket"
                  : currentMode === "comment"
                  ? "Add Comment"
                  : "Create Ticket"}
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default DuringCalls;
