import { Box, Card, Grid, Paper, TextField } from "@mui/material";
import CustomScrollBarBox from "app/components/Shared/CustomScrollBarBox/CustomScrollBarBox";
import { useState } from "react";
import AdminUserInfoComponent from "./UserPageComponents/AdminUserInfoComponent";
import UserListComponent from "./UserPageComponents/UserListComponent";

const AdminTab = ({
  data,
  onEdit,
  onDelete,
  handleFilterChange,
  filterData,
  snackbarData,
  handleSnackbarClose,
  setSnackbarData,
}) => {
  // set data in admin data only if data is there
  const [adminData, setAdminData] = useState(data.length > 0 ? data[0] : null);
  const [searchValue, setSearchValue] = useState(filterData.extension || "");

  // to render the component with the user data
  if (data.length > 0 && !adminData) {
    setAdminData(data[0]);
  }

  const handleUserClick = (userData) => {
    setAdminData(userData);
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setSearchValue(newValue);
    if (!newValue) {
      handleFilterChange("extension", "");
      setAdminData(null);
    }
  };

  const handleSearchEnter = (event) => {
    if (event.key === "Enter") {
      handleFilterChange("extension", searchValue);
    }
  };
  return (
    <Box sx={{ height: "100%", mt: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={4.5}>
          <Paper
            sx={{
              width: "100%",
              height: "98vh",
              borderRadius: 4,
              pt: 1,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "end", m: 2 }}>
              <TextField
                label="Search By Ext No."
                placeholder="Enter"
                size="small"
                color="primary"
                value={searchValue}
                onChange={handleInputChange}
                onKeyDown={handleSearchEnter}
                type="search"
              />
            </Box>
            <CustomScrollBarBox
              height={"82vh"}
              scrollbarWidth={"6px"}
              mb={5}
              p={2}
            >
              {data.map((userData) => (
                <Box key={userData.id} mb={2}>
                  <UserListComponent
                    userData={userData}
                    onClick={handleUserClick}
                  />
                </Box>
              ))}
            </CustomScrollBarBox>
          </Paper>
        </Grid>
        <Grid item xs={6.5}>
          {adminData && (
            <Card
              sx={{
                width: "80%",
                borderRadius: 4,
                p: 2,
              }}
            >
              <AdminUserInfoComponent
                adminData={adminData}
                onEdit={onEdit}
                onDelete={onDelete}
                snackbarData={snackbarData}
                handleSnackbarClose={handleSnackbarClose}
                setSnackbarData={setSnackbarData}
              />
            </Card>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminTab;
