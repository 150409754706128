import React, { useMemo, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  Box,
  Chip,
} from "@mui/material";
import CustomScrollBarBox from "../CustomScrollBarBox/CustomScrollBarBox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiSelectComponent = ({
  initialOptions,
  label,
  value,
  name,
  required,
  handleChange,
}) => {
  const [options] = useState(initialOptions);
  const [selectedValues, setSelectedValues] = useState(value || []);

  const handleSelectChange = (event) => {
    const selected = event.target.value;
    setSelectedValues(selected);
    handleChange(selected);
  };

  // Memoize the chips to avoid unnecessary renders
  const selectedChips = useMemo(
    () =>
      options
        .filter((option) => selectedValues.includes(option.id))
        .map((option) => <Chip key={option.id} label={option.label} />),
    [options, selectedValues]
  );

  return (
    <FormControl
      fullWidth
      sx={{
        mb: 2,
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#23239C",
          "&:focus-within": {
            borderColor: "#23239C",
            color: "#23239C",
          },
          "&:hover": {
            borderColor: "grey",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#23239C",
            color: "#23239C",
          },
        },
        "& .MuiFormLabel-root": {
          color: "#23239C",
        },
      }}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        name={name}
        multiple
        label={label}
        required={required}
        value={selectedValues}
        onChange={handleSelectChange}
        inputProps={{ name: name }}
        renderValue={() => (
          <CustomScrollBarBox
            height="auto"
            minHeight="40px"
            maxHeight="80px"
            scrollbarWidth="4px"
            mb={0.5}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
              }}
            >
              {selectedChips}
            </Box>
          </CustomScrollBarBox>
        )}
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <Checkbox checked={selectedValues.includes(option.id)} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelectComponent;
