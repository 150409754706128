import React, { useEffect } from "react";
import ApexCharts from "apexcharts";

const CustomDonutChart = ({ seriesData, seriesNames, seriesColors }) => {
  useEffect(() => {
    const options = {
      series: seriesData,
      chart: {
        type: "donut",
        width: 400,
        height: 400,
      },
      labels: seriesNames,
      colors: seriesColors,
      plotOptions: {
        pie: {
          donut: {
            size: "40%",
          },
        },
      },
      legend: {
        position: "left",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };

    const chart = new ApexCharts(
      document.querySelector("#customDonutChart"),
      options
    );
    chart.render();

    // Clean up chart instance on unmount
    return () => {
      chart.destroy();
    };
  }, [seriesData, seriesNames, seriesColors]);

  return <div id="customDonutChart" />;
};

export default CustomDonutChart;
