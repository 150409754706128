import { Box } from "@mui/material";
import Chart from "react-apexcharts";

const ChartComponent = ({ categories, colors, series }) => {
  const options = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    grid: {
      row: {
        colors: ["#ffffff", "transparent"],
        opacity: 0.5,
      },
    },
    tooltip: {
      enabled: true,
    },
    xaxis: {
      categories: categories,
    },
    legend: {
      position: "right",
    },
    colors: colors,
  };

  return (
    <Box
      width="100%"
      height="100%"
      sx={{
        flex: 1,
      }}
    >
      <Chart
        options={options}
        series={series}
        type="line"
        height="100%"
        width="100%"
      />
    </Box>
  );
};

export default ChartComponent;
