import { Box } from "@mui/material";
import React from "react";

// custom box with a customized scrollbar used for data grid
// use autoheight in data grid
const CustomScrollBarBox = ({ children, height, scrollbarWidth, mb, p }) => {
  return (
    <Box
      sx={{
        height: height,
        overflowX: "auto",
        overflowY: "auto",
        padding: p,
        // border: "1px solid #d3d3d3",
        // borderRadius: "4px",
        marginBottom: mb,
        "&::-webkit-scrollbar": {
          width: scrollbarWidth,
          height: scrollbarWidth,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#ccc",
          borderRadius: "3px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#aaa",
        },
      }}
    >
      {children}
    </Box>
  );
};

export default CustomScrollBarBox;
