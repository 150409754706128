import { Box, Grid, Paper, TextField } from "@mui/material";
import CustomScrollBarBox from "app/components/Shared/CustomScrollBarBox/CustomScrollBarBox";
import { useState } from "react";
import UserInfoComponent from "./UserPageComponents/UserInfoComponent";
import UserListComponent from "./UserPageComponents/UserListComponent";

const SupervisorTab = ({
  data,
  onEdit,
  onDelete,
  handleFilterChange,
  filterData,
  snackbarData,
  handleSnackbarClose,
  setSnackbarData,
  handleDeleteOpen,
  handleDeleteClose,
  openDelete,
  isLoading,
  deleteLoading
}) => {
  // set data in supervisor data only if data is there
  const [supervisorData, setSupervisorData] = useState(
    data.length > 0 ? data[0] : null
  );
  const [searchValue, setSearchValue] = useState(filterData.extension || "");

  if (!isLoading && data.length > 0 && !supervisorData) {
    setSupervisorData(data[0]);
  }

  const handleUserClick = (userData) => {
    setSupervisorData(userData);
  };

  const handleUserDelete = (deletedUser) => {
    onDelete(deletedUser);
    // Check if the currently displayed user info matches the deleted user
    if (supervisorData && supervisorData._id === deletedUser._id) {
      // Reset supervisorData to null
      setSupervisorData(null);
    }
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setSearchValue(newValue);
    if (!newValue) {
      handleFilterChange("extension", "");
      setSupervisorData(null);
    }
  };

  const handleSearchEnter = (event) => {
    if (event.key === "Enter") {
      handleFilterChange("extension", searchValue);
    }
  };
  return (
    <Box sx={{ height: "100%", mt: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={4.5}>
          <Paper
            sx={{
              width: "100%",
              height: "98vh",
              borderRadius: 4,
              pt: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                m: 2,
                justifyContent: "end",
                pr: 1,
              }}
            >
              <TextField
                label="Search By Ext No."
                placeholder="Enter"
                size="small"
                color="primary"
                value={searchValue}
                onChange={handleInputChange}
                onKeyDown={handleSearchEnter}
                type="search"
              />
            </Box>
            <CustomScrollBarBox
              height={"82vh"}
              scrollbarWidth={"6px"}
              mb={5}
              p={2}
            >
              {data.map((data) => (
                <Box key={data?.id} mb={2}>
                  <UserListComponent
                    userData={data}
                    onClick={handleUserClick}
                  />
                </Box>
              ))}
            </CustomScrollBarBox>
          </Paper>
        </Grid>
        <Grid item xs={6.5}>
          {supervisorData && (
            <UserInfoComponent
              userData={supervisorData}
              tabValue={2}
              onEdit={onEdit}
              onDelete={handleUserDelete}
              snackbarData={snackbarData}
              handleSnackbarClose={handleSnackbarClose}
              setSnackbarData={setSnackbarData}
              handleDeleteOpen={handleDeleteOpen}
              handleDeleteClose={handleDeleteClose}
              openDelete={openDelete}
              deleteLoading={deleteLoading}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SupervisorTab;
