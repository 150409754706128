import React, { useEffect, useState } from "react";
import { Alert, Box, Grid, Snackbar } from "@mui/material";
import RecentActivity from "./RecentActivity";
import DuringCalls from "./DuringCalls";
import { useLocation } from "react-router-dom";
import ClientDetailsComponent from "./ClientDetails/ClientDetails";
import { instance } from "app/utils/axiosRequest";
import { useParams } from "react-router-dom";
import {
  addCommentTicketPageAdmin,
  adminGetStatusTypes,
  adminGetTicketByPhone,
  adminTicketV2,
  admingetAllticketType,
  allQueueList,
  allcategoryList,
  createTicketAdmin,
  getAllDepartmentAdmin,
  getTicketbyIdV2,
  singleQueue,
} from "app/utils/api-urls/AdminAPiUrls";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  addCommentTicketPage,
  agentGetStatusTypes,
  agentGetTicketByPhone,
  agentcategoryList,
  agentgetAllticketType,
  createTicketAgent,
  getAllDepartmentAgent,
  getTicketById,
} from "app/utils/api-urls/AgentApiUrls";
import { useHistory } from "react-router-dom";
import {
  addCommentTicketPageSupervisor,
  createTicketSupervisor,
  getAllDepartmentSupervisor,
  getAllticketTypeSupervisor,
  supervisorGetStatusTypes,
  supervisorGetTicketByPhone,
  supervisorGetallQueue,
  supervisorTicketV2,
  supervisorallcategoryList,
  supervisorgetTicketByIdV2,
} from "app/utils/api-urls/SupervisorAPiUrls";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { currentUuid } from "app/store/ticketUid/TicketUidAction";

const TicketPageV2 = () => {
  const options = ["Option 1", "Option 2", "Option 3"];
  const { ticketId } = useParams();
  const [ticket, setTicket] = useState(null);
  const hasTicketLogs = ticket?.ticketLogs?.length > 0;
  const role = localStorage.getItem("role");
  const history = useHistory();
  const [statusOptions, setStatusOptions] = useState({
    raised_closed: "",
    closed: "",
    assign: "",
  });

  // console.log(statusOptions, "sttaus");

  // const statusOptions = {
  //   raised_closed: "658294e167e5ed697daef786",
  //   closed: "658294ca9777467f6777802e",
  //   assign: "658294f1660cac3e3acda29a",
  // };

  // console.log(statusOptions, "first");

  const dispatch = useDispatch();
  const { current_uuid } = useSelector((state) => state.TicketUidReducer);
  const [selectedClient, setSelectedClient] = useState(null);
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const [duringCallValue, setDuringCallValue] = useState({
    issueCategories: [],
    selectedIssueCategory: ticket?.issueId?._id || null,
    subIssueCategories: [],
    selectedSubIssueCategory: ticket?.subIssueId?._id || null,
    remarks: ticket?.remarks || "",
    queues: [],
    selectedQueue: null,
    agents: [],
    selectedAgent: null,
    status: hasTicketLogs ? statusOptions.closed : statusOptions.raised_closed,
    comment: "",
    latestTicketLogId: null,
    updateRemarks: "",
    typeId: "",
    recordingId: "",
  });

  const [clientDetails, setClientDetails] = useState({
    clientId: null,
    clientEmail: null,
    clientName: null,
    clientImage: null,
    status: null,
    clientPhone: null,
    clientFatherName: null,
    clientGrandFatherName: null,
  });

  const [callerInfo, setCallerInfo] = useState({
    callerPhone: "",
    callerName: "",
    relation: "",
    self: false,
  });

  const [showCallerDetails, setShowCallerDetails] = useState(false);

  const { isLoading: ticketIsLoading } = useQuery(
    ["ticketDetails", ticketId, role],
    () => {
      const endpoint = API_ENDPOINTS_BY_ROLE[role]?.getTicketById;
      return instance.get(`${endpoint}/${ticketId}`);
    },
    {
      onSuccess: (response) => {
        setTicket(response.data);
      },
      enabled: !!ticketId && !!role,
    }
  );

  useEffect(() => {
    const lastLogId =
      ticket?.ticketLogs?.length > 0
        ? ticket.ticketLogs[ticket.ticketLogs.length - 1]._id
        : null;
    setDuringCallValue((prevState) => ({
      ...prevState,
      selectedIssueCategory: ticket?.issueId?._id || null,
      selectedSubIssueCategory: ticket?.subIssueId?._id || null,
      remarks: ticket?.remarks || "",
      latestTicketLogId: lastLogId,
    }));
  }, [ticket]);

  useEffect(() => {
    if (ticket?.clientInfo) {
      setClientDetails({
        clientId: ticket.clientInfo.clientId,
        clientEmail: ticket.clientInfo.clientEmail,
        clientName: ticket.clientInfo.clientName,
        clientImage: ticket.clientImage,
        status: ticket.clientInfo.status,
        clientPhone: ticket.clientInfo.clientPhone,
      });
    }
  }, [ticket]);

  const fetchClientDetails = async ({ queryKey }) => {
    const [_key, { current_uuid }] = queryKey;
    const response = await axios.get(
      `${process.env.REACT_APP_NEW_KYC_API}${current_uuid?.caller_id}`,
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
      }
    );
    return response.data;
  };

  const { error } = useQuery(
    ["clientDetails", { current_uuid }],
    fetchClientDetails,
    {
      enabled: !!current_uuid && !ticket?.clientInfo,
      onSuccess: (data) => {
        if (data && data.length > 0) {
          const client = data[0];
          const fullName = [
            client.firstName,
            client.middleName,
            client.lastName,
          ]
            .filter(Boolean)
            .join(" ");

          setClientDetails({
            clientId: client.clientId,
            clientEmail: client.email,
            clientName: fullName,
            clientImage: client.profileImage,
            status: client.statusName,
            clientPhone: client.contactNo,
            clientFatherName: client?.fathersName,
            clientGrandFatherName: client?.grandFathersName,
          });
          dispatch(currentUuid(null));
        } else {
          console.error("Received unexpected data format:", data);
          setClientDetails(null);
        }
      },
      onError: (error) => {
        console.error("Error fetching client details:", error);
        setClientDetails(null);
      },
    }
  );

  const API_ENDPOINTS_BY_ROLE = {
    admin: {
      issueCategories: allcategoryList.category_list,
      departmentList: getAllDepartmentAdmin.admin_get_all_department,
      getTicketByPhone: adminGetTicketByPhone.admin_get_ticket_by_phone,
      getStatusType: adminGetStatusTypes.admin_get_status_types,
      getTicketById: getTicketbyIdV2.Admin_get_ticket_by_id,
      createTicket: createTicketAdmin.admin_create_ticket,
      addComment: addCommentTicketPageAdmin.admin_add_comment,
      ticketType: admingetAllticketType.admin_get_all_ticket_type,
    },
    agent: {
      issueCategories: agentcategoryList.agent_category_list,
      departmentList: getAllDepartmentAgent.agent_get_all_department,
      getTicketByPhone: agentGetTicketByPhone.agent_get_ticket_by_phone,
      getStatusType: agentGetStatusTypes.agent_get_status_types,
      getTicketById: getTicketById.agentget_ticket_by_id,
      createTicket: createTicketAgent.agent_create_ticket,
      addComment: addCommentTicketPage.agent_add_comment,
      ticketType: agentgetAllticketType.agent_get_all_ticket_type,
    },
    supervisor: {
      issueCategories: supervisorallcategoryList.supervisor_category_list,
      departmentList: getAllDepartmentSupervisor.supervisor_get_all_department,
      getTicketByPhone:
        supervisorGetTicketByPhone.supervisor_get_ticket_by_phone,
      getStatusType: supervisorGetStatusTypes.supervisor_get_status_types,
      getTicketById: supervisorgetTicketByIdV2.get_ticket_by_id,
      createTicket: createTicketSupervisor.supervisor_create_ticket,
      addComment: addCommentTicketPageSupervisor.supervisor_add_comment,
      ticketType: getAllticketTypeSupervisor.supervisor_get_all_ticket_type,
    },
  };

  const { isLoading: getTicketByPhoneLoading, data: getTicketByPhoneData } =
    useQuery(["get-Ticket-by-Phone", role, clientDetails?.clientPhone], () => {
      const url = `${API_ENDPOINTS_BY_ROLE[role]?.getTicketByPhone}/${clientDetails?.clientPhone}`;
      return instance.get(url);
    });

  const { data: getTicketTypeData } = useQuery(
    ["get-Ticket_type", role],
    () => {
      const url = API_ENDPOINTS_BY_ROLE[role]?.ticketType;
      return instance.get(url);
    }
  );
  const { data: getStatusType } = useQuery(["get-Status-type", role], () => {
    const url = API_ENDPOINTS_BY_ROLE[role]?.getStatusType;
    return instance.get(url);
  });

  useQuery(
    ["issue-categories", role],
    () => instance.get(API_ENDPOINTS_BY_ROLE[role]?.issueCategories),
    {
      onSuccess: (data) => {
        setDuringCallValue((prevState) => ({
          ...prevState,
          issueCategories: data?.data?.map((cat) => ({
            id: cat._id,
            value: cat._id,
            label: cat.name,
          })),
        }));
      },
    }
  );

  const { isLoading: subissueCategoryLoading } = useQuery(
    ["sub-issue-categories", duringCallValue.selectedIssueCategory, role],
    () => {
      const url = `${API_ENDPOINTS_BY_ROLE[role]?.issueCategories}/${duringCallValue.selectedIssueCategory}`;
      return instance.get(url);
    },
    {
      enabled: !!duringCallValue?.selectedIssueCategory,
      onSuccess: (data) => {
        setDuringCallValue((prevState) => ({
          ...prevState,
          subIssueCategories: data?.data?.map((subCat) => ({
            id: subCat._id,
            value: subCat._id,
            label: subCat.name,
          })),
        }));
      },
    }
  );

  useQuery(
    ["department-list", role],
    () => instance.get(API_ENDPOINTS_BY_ROLE[role]?.departmentList),
    {
      onSuccess: (data) => {
        setDuringCallValue((prevState) => ({
          ...prevState,
          queues: data?.data?.data?.map((queue) => ({
            id: queue._id,
            value: queue._id,
            label: queue.name,
          })),
        }));
      },
    }
  );

  useQuery(
    ["department-details", duringCallValue?.selectedQueue],
    () =>
      instance.get(
        `${API_ENDPOINTS_BY_ROLE[role]?.departmentList}/${duringCallValue.selectedQueue}`
      ),
    {
      enabled: !!duringCallValue?.selectedQueue,
      onSuccess: (data) => {
        setDuringCallValue((prevState) => ({
          ...prevState,
          agents: data?.data?.data?.agents.map((agent) => ({
            id: agent._id,
            value: agent._id,
            label: `${agent.firstName} (${agent.extension})`,
          })),
        }));
      },
    }
  );

  const { mutate: createTicketMutate } = useMutation(
    ["create-ticket", role],
    (payload) => {
      const url = API_ENDPOINTS_BY_ROLE[role]?.createTicket;
      return instance?.post(url, payload);
    },
    {
      onSuccess: () => {
        setSnackBar({
          open: true,
          severity: "success",
          message: "Ticket created successfully!",
        });
        history.push({
          pathname: "/app/ticket/ticket-listV2",
        });
      },
      onError: (error) => {
        const errorMessage = error?.response?.data?.message;

        setSnackBar({
          open: true,
          severity: "error",
          message: `Failed to create ticket: ${errorMessage}`,
        });
      },
    }
  );
  const { mutate: addCommentMutate } = useMutation(
    ({ ticketId, payload }) => {
      const url = `${API_ENDPOINTS_BY_ROLE[role]?.addComment}/${ticketId}`;
      return instance.patch(url, payload);
    },
    {
      onSuccess: () => {
        setSnackBar({
          open: true,
          severity: "success",
          message: "Comment added sucessfully!",
        });
        history.push({
          pathname: "/app/ticket/ticket-listV2",
        });
      },
      onError: () => {
        setSnackBar({
          open: true,
          severity: "error",
          message: error?.message || "Failed to add comment!",
        });
      },
    }
  );

  const { mutate: updateTicketMutate } = useMutation(
    ({ ticketId, payload }) => {
      const url = `${API_ENDPOINTS_BY_ROLE[role]?.createTicket}/${ticketId}`;
      return instance.patch(url, payload);
    },
    {
      onSuccess: () => {
        setSnackBar({
          open: true,
          severity: "success",
          message: "Ticket Updated sucessfully!",
        });
        history.push({
          pathname: "/app/ticket/ticket-listV2",
        });
      },
      onError: () => {
        setSnackBar({
          open: true,
          severity: "error",
          message: error?.message || "Failed to update ticket!",
        });
      },
    }
  );

  useEffect(() => {
    const callTypeId = getTicketTypeData?.data?.find(
      (type) => type.name === "Call"
    )?._id;
    const manualTypeId = getTicketTypeData?.data?.find(
      (type) => type.name === "Manual"
    )?._id;
    const typeId = current_uuid ? callTypeId : manualTypeId;
    setDuringCallValue((prevValues) => ({
      ...prevValues,
      typeId: typeId,
    }));

    if (current_uuid && current_uuid?.recording_id) {
      setDuringCallValue((prevValues) => ({
        ...prevValues,
        recordingId: current_uuid?.recording_id,
      }));
    }
    if (current_uuid && current_uuid?.caller_id) {
      setCallerInfo((prevValues) => ({
        ...prevValues,
        callerPhone: current_uuid?.caller_id,
      }));
    }
  }, [current_uuid, getTicketTypeData?.data]);

  useEffect(() => {
    if (getStatusType?.data?.data) {
      const statusMap = {
        raised_closed: "",
        closed: "",
        assign: "",
      };

      // Iterate through each status and match specifically
      getStatusType?.data?.data?.forEach((status) => {
        const nameLower = status.name.toLowerCase().trim();
        // Exact matches for each status type
        if (nameLower === "closed") {
          statusMap.closed = status._id;
        } else if (nameLower === "raised and closed") {
          statusMap.raised_closed = status._id;
        } else if (nameLower === "assigned") {
          statusMap.assign = status._id;
        }
      });

      setStatusOptions(statusMap);
    }
  }, [getStatusType]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar({
      ...snackBar,
      open: false,
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCallerInfo({
      ...callerInfo,
      [name]: value,
    });
  };

  const handleSwitchChange = (event) => {
    setCallerInfo({
      ...callerInfo,
      self: event.target.checked,
    });
  };

  const handleClientChange = (client, callerNumber) => {
    setSelectedClient(client);
    if (callerNumber) {
      setShowCallerDetails(true);
    }
    const fullName = [client?.firstName, client?.middleName, client?.lastName]
      .filter(Boolean)
      .join(" ");

    setClientDetails({
      clientId: client?.clientId,
      clientEmail: client?.email,
      clientName: fullName,
      clientImage: client?.profileImage,
      status: client?.statusName,
      clientPhone: client?.contactNo,
      clientFatherName: client?.fathersName,
      clientGrandFatherName: client?.grandFathersName,
    });
  };
  const handleSelectIssueCategory = (event) => {
    const categoryId = event.target.value;
    if (!hasTicketLogs) {
      setDuringCallValue((prevState) => ({
        ...prevState,
        selectedIssueCategory: categoryId,
      }));
    }
  };

  const handleSelectQueue = (event) => {
    const queueId = event.target.value;
    setDuringCallValue((prevState) => ({
      ...prevState,
      selectedQueue: queueId,
    }));
  };

  const handleSelectAgent = (event) => {
    const agentId = event.target.value;
    setDuringCallValue((prevState) => ({
      ...prevState,
      selectedAgent: agentId,
    }));
  };

  const handleSelectSubissueCategory = (event) => {
    const subissueCategoryId = event.target.value;
    if (!hasTicketLogs) {
      setDuringCallValue((prevState) => ({
        ...prevState,
        selectedSubIssueCategory: subissueCategoryId,
      }));
    }
  };

  // Handler for remarks change
  const handleRemarksChange = (event) => {
    const newremarks = event.target.value;
    if (!hasTicketLogs) {
      setDuringCallValue((prevState) => ({
        ...prevState,
        remarks: newremarks,
      }));
    }
  };

  const handleUpdateRemarksChange = (event) => {
    const newremarks = event.target.value;

    setDuringCallValue((prevState) => ({
      ...prevState,
      updateRemarks: newremarks,
    }));
  };

  const handleStatusChange = (event) => {
    setDuringCallValue((prevState) => ({
      ...prevState,
      status: event.target.value,
    }));
  };

  const handleCommentChange = (event) => {
    const { value } = event.target;
    setDuringCallValue((prevState) => ({
      ...prevState,
      comment: value,
    }));
  };

  const handleCreateTicket = () => {
    const payload = {
      issueId: duringCallValue?.selectedIssueCategory,
      subIssueId: duringCallValue?.selectedSubIssueCategory,
      typeId: duringCallValue?.typeId,
      statusId: duringCallValue?.status,
      clientInfo: {
        clientId: clientDetails?.clientId,
        clientEmail: clientDetails?.clientEmail,
        clientPhone: clientDetails?.clientPhone,
        clientName: clientDetails?.clientName,
        clientImage: clientDetails?.clientImage,
      },
      callerInfo: {
        callerPhone: current_uuid?.caller_id,
        callerName: callerInfo?.callerName,
        relation: callerInfo?.relation,
        self: false,
      },
      remarks: duringCallValue?.remarks,
      recordingId: duringCallValue?.recordingId,
    };

    createTicketMutate(payload);
  };

  const handleUpdateTicket = () => {
    const payload = {
      remarks: duringCallValue?.updateRemarks,
      statusId: duringCallValue?.status,
      assignedTo: duringCallValue?.selectedAgent,
      assignedDepartment: duringCallValue?.selectedQueue,
    };

    updateTicketMutate({
      ticketId: ticketId,
      payload: payload,
    });
  };

  const handleCommentUpdate = () => {
    const latestTicketLogId =
      ticket.ticketLogs[ticket.ticketLogs.length - 1]?._id;
    const payload = {
      ticketLogId: latestTicketLogId,
      comment: duringCallValue?.comment,
    };
    addCommentMutate({
      ticketId: ticketId,
      payload: payload,
    });
  };
  const isActionAvailable = (action) => {
    return ticket?.action?.includes(action);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, paddingTop: "1em" }}>
        <Snackbar
          open={snackBar.open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={snackBar.severity}
            sx={{ width: "100%" }}
          >
            {snackBar.message}
          </Alert>
        </Snackbar>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <ClientDetailsComponent
              clientDetails={clientDetails}
              callerNumber={current_uuid?.caller_id}
              options={options}
              handleClientChange={handleClientChange}
              callerInfo={callerInfo}
              handleInputChange={handleInputChange}
              handleSwitchChange={handleSwitchChange}
              showCallerDetails={showCallerDetails}
            />
            <DuringCalls
              duringCallValue={duringCallValue}
              handleSelectIssueCategory={handleSelectIssueCategory}
              handleSelectSubissueCategory={handleSelectSubissueCategory}
              handleSelectQueue={handleSelectQueue}
              handleSelectAgent={handleSelectAgent}
              handleRemarksChange={handleRemarksChange}
              handleCreateTicket={handleCreateTicket}
              allTicketData={ticket}
              handleStatusChange={handleStatusChange}
              statusOptions={statusOptions}
              handleCommentChange={handleCommentChange}
              handleUpdateRemarksChange={handleUpdateRemarksChange}
              handleCommentUpdate={handleCommentUpdate}
              handleUpdateTicket={handleUpdateTicket}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              <RecentActivity
                TicketDataByPhone={getTicketByPhoneData?.data?.data}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default TicketPageV2;
