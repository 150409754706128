import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { TextField, Autocomplete } from "@mui/material";
import useDebounce from "..//../utils/hooks/useDebounce";

const fetchClients = async (searchQuery) => {
  const response = await fetch(
    `${process.env.REACT_APP_NEW_KYC_API}${searchQuery}`,
    {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

const ClientAutoCompleteComponent = ({
  width = "320px",
  handleClientChange,
  error,
  helperText,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const debouncedSearchValue = useDebounce(inputValue, 500);

  const { data: options, isFetching } = useQuery(
    ["fetchClients", debouncedSearchValue],
    async () => {
      const encodedSearchQuery = encodeURIComponent(debouncedSearchValue);
      return fetchClients(encodedSearchQuery);
    },
    {
      enabled: !!debouncedSearchValue,
      keepPreviousData: true,
      select: (data) => data?.slice(0, 20),
    }
  );

  const handleOnChange = (event, newValue) => {
    setSelectedValue(newValue);
    handleClientChange(newValue);
  };

  return (
    <div>
      <Autocomplete
        freeSolo
        inputValue={inputValue}
        value={selectedValue}
        onChange={handleOnChange}
        onInputChange={(event, newValue) => {
          setInputValue(newValue);
        }}
        options={options || []}
        getOptionLabel={(option) =>
          option
            ? `${option?.firstName || ""} ${option?.lastName || ""}  (${
                option?.clientId || "N/A"
              }) - ${option?.contactNo || ""}`
            : ""
        }
        loading={isFetching}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search by Client-Name/Code/Number"
            error={error}
            helperText={helperText}
            fullWidth
            sx={{
              width,
            }}
            InputProps={{
              ...params.InputProps,
              sx: {
                ...params.InputProps.sx,
                borderRadius: "15px",
                border: "none",
                backgroundColor: "#F5F5F5",
              },
              disableUnderline: true,
            }}
          />
        )}
      />
    </div>
  );
};

export default ClientAutoCompleteComponent;
