import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import setupRoutes from "./setupRoutes";
import BreadcrumbHeader from "app/components/BreadcrumbHeader";

import reportRoutes from "./reportRoutes";
import faqRoutes from "./faqRoutes";
import settingRoutes from "./settingRoutes";
import ticketRoutes from "./ticketRoutes";

import adminDashboardRoutes from "./adminDashboardRoutes";
import AgentReport from "app/pages/AgentReports";
import Myprofile from "app/pages/AgentProfile";
import AgentReportRoutes from "./agentReportRoutes";
import agentReportTicket from "./agentTicketReport";
import outboundCampaignRoutes from "./outboundCampaignRoutes";
import SupervisorReportRoutes from "./supervisorReport";
import SupervisorTicketReport from "./supervisorTicketReport";
import SupervisorTicketReportRoutes from "./supervisorTicketReport";
import agentoutboundCampaignRoutes from "./agentoutboundCampaignRoutes";
import callBlastingRoutes from "./callBlastingRoutes";

const Dashboard = lazy(() => import("app/pages/dashboard/Dashboard"));
const AssignedToMe = lazy(() => import("app/pages/assignedToMe"));

export const routes = [
  {
    path: "/app/dashboard",
    shortPath: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    icon: "home",
    hideInSidebar: true,
    auth: [],
  },

  {
    path: "/app/ticket",
    shortPath: "/ticket",
    name: "Ticket",
    dropDownState: "ticketOpen",
    dropDownMenu: ticketRoutes,
    icon: "tag-outline",
    auth: ["admin", "admin"],
  },

  {
    path: "/app/report",
    shortPath: "/report",
    name: "Report",
    dropDownState: "reportOpen",
    dropDownMenu: AgentReportRoutes,
    icon: "mdi mdi-chart-tree",
    auth: ["agent", "agent"],
  },

  {
    path: "/app/report",
    shortPath: "/report",
    name: "Report",
    dropDownState: "reportOpen",
    dropDownMenu: SupervisorReportRoutes,
    icon: "mdi mdi-chart-tree",
    auth: ["supervisor", "supervisor"],
  },

  {
    path: "/app/ticket",
    shortPath: "/ticket",
    name: "Ticket",
    dropDownState: "ticketOpen",
    dropDownMenu: SupervisorTicketReportRoutes,
    icon: "mdi mdi-chart-tree",
    auth: ["supervisor", "supervisor"],
  },

  {
    path: "/app/ticket",
    shortPath: "/report",
    name: "Ticket ",
    dropDownState: "ticketOpen",
    dropDownMenu: agentReportTicket,
    icon: "tag-outline",
    auth: ["agent", "agent"],
  },

  {
    path: "/app/my-profile",
    shortPath: "/my-Profile",
    name: "profile",
    component: Myprofile,
    icon: "mdi mdi-chart-tree",
    auth: ["agent"],
    hideInSidebar: true,
  },

  // {
  //   path: "/app/assigned-to-me",
  //   shortPath: "/assigned-to-me",
  //   name: "Assigned Me",
  //   component: AssignedToMe,
  //   icon: "format-list-bulleted",
  //   auth: [],
  // },

  // {
  //   path: "/app/admin/daily-activities/view",
  //   shortPath: "/admin/daily-activities/view",
  //   name: "View Activities",
  //   icon: "mdi mdi-account-convert",
  //   component: ViewActivities,
  //   hideInSidebar: true,
  //   auth: [],
  // },

  // for later
  // {
  //   path: "/app/logs",
  //   shortPath: "/logs",
  //   name: "Logs",
  //   dropDownState: "logOpen",
  //   dropDownMenu: logRoutes,
  //   icon: "format-list-checkbox",
  //   auth: [],
  // },
  {
    path: "/app/admin-dashboard",
    shortPath: "/admin-dashboard",
    name: "Report",
    dropDownState: "adminDashboardOpen",
    dropDownMenu: adminDashboardRoutes,
    icon: "mdi mdi-chart-tree",
    auth: ["admin", "admin"],
  },
  {
    path: "/app/settings",
    shortPath: "/settings",
    name: "Settings",
    dropDownState: "settingsOpen",
    dropDownMenu: settingRoutes,
    icon: "settings",
    auth: ["admin", "admin"],
  },
  {
    path: "/app/call-blasting",
    shortPath: "/call-blasting",
    name: "Call-Blasting",
    dropDownState: "callBlastingOpen",
    dropDownMenu: callBlastingRoutes,
    icon: "mdi mdi-gesture-tap-button",
    auth: ["admin", "agent"],
  },

  // {
  //   path: "/app/outbound-campaign",
  //   shortPath: "/outbound-campaign",
  //   name: "Outbound Campaign",
  //   dropDownState: "outboundCampaignOpen",
  //   dropDownMenu: outboundCampaignRoutes,
  //   icon: "phone",
  //   auth: ["admin", "admin"],
  // },

  // {
  //   path: "/app/outbound-campaign",
  //   shortPath: "/outbound-campaign",
  //   name: "Outbound Campaign ",
  //   dropDownState: "outboundCampaignOpen ",
  //   dropDownMenu: agentoutboundCampaignRoutes,
  //   icon: "mdi mdi-bullhorn",
  //   auth: ["agent", "agent"],
  // },

  // {
  //   path: "/app/faq",
  //   shortPath: "/faq",
  //   name: "FAQ",
  //   dropDownState: "faqOpen",
  //   dropDownMenu: faqRoutes,
  //   icon: "comment-question-outline",
  //   auth: [],
  // },
];

const allRoutes = [
  ...routes,
  ...faqRoutes,
  ...reportRoutes,
  ...settingRoutes,
  ...setupRoutes,
  ...ticketRoutes,
  ...outboundCampaignRoutes,
  ...adminDashboardRoutes,
  ...AgentReportRoutes,
  ...agentReportTicket,
  ...SupervisorReportRoutes,
  ...SupervisorTicketReportRoutes,
  ...agentoutboundCampaignRoutes,
  ...callBlastingRoutes,
];

const Routes = () => {
  return (
    <Suspense fallback={"loading..."}>
      <Switch>
        <Route exact path="/app/dashboard" component={Dashboard} />
        <Route exact path="/app/assigned-to-me" component={AssignedToMe} />
        {/* <Route exact path="/app/client-kyc/:id" component={ClientKyc} /> */}
        {/* <Route
          exact
          path="/app/sub-issue-category"
          component={SubIssueCategory}
        /> */}
        {/* <Route
          exact
          path="/app/primary-description"
          component={primaryDescription}
        /> */}

        {/* <Route
          exact
          path="/app/secondary-description"
          component={SecondaryDescription}
        /> */}

        {allRoutes.map(({ path, exact, component: Component, ...e }) => {
          if (Component) {
            return (
              <Route
                key={path}
                exact={true}
                path={path}
                component={() => (
                  <>
                    {path === "/app/ticket/new-follow-up" ? (
                      <></>
                    ) : (
                      <BreadcrumbHeader title={e.name} icon={e.icon} />
                    )}
                    <Component />
                  </>
                )}
              />
            );
          } else return null;
        })}
        <Redirect to="/app/dashboard" />
      </Switch>
    </Suspense>
  );
};

export default Routes;
