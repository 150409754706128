import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import PlaylistPlayIcon from "@mui/icons-material/PlaylistPlay";
import UploadIcon from "@mui/icons-material/Upload";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import CustomDataGrid from "app/components/Shared/DataGrid/CustomDataGrid";
import DatePicker from "app/components/Shared/DatePicker/DatePicker.component";
import { adminCallBlasting } from "app/utils/api-urls/AdminAPiUrls";
import { instance } from "app/utils/axiosRequest";
import dayjs from "dayjs";
import qs from "qs";
import { Suspense, lazy, useRef, useState } from "react";

const ConfirmDialogBox = lazy(() =>
  import("app/components/Shared/ConfirmDialogBox/ConfirmDialogBox")
);
const SnackbarComponent = lazy(() => import("app/components/SnackBar"));
const ReactAudioPlayer = lazy(() => import("react-audio-player"));

const AudioManagement = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState(null);
  const [selectedAudioId, setSelectedAudioId] = useState(null);
  const [filterData, setFilterData] = useState({
    startDate: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  });
  const [snackbarData, setSnackbarData] = useState({
    open: false,
    severity: "error",
    message: "",
  });
  const [audioSrc, setAudioSrc] = useState(null);

  const columns = [
    { field: "name", headerName: "File Name", flex: 1 },
    { field: "fileSize", headerName: "File Size", flex: 1 },
    { field: "duration", headerName: "Duration", flex: 1 },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      renderCell: (params) => dayjs(params?.row?.date).format("YYYY-MM-DD"),
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      sticky: "left",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {selectedAudioId === params?.row?._id ? (
            <ReactAudioPlayer
              src={audioSrc}
              autoPlay
              onEnded={() => {
                setAudioSrc(null);
                setSelectedAudioId(null);
              }}
              controls
              controlsList={false}
              volume={false}
              style={{
                fontSize: "5px",
                height: "20px",
                width: "130px",
              }}
            />
          ) : (
            <Tooltip title="Play">
              <IconButton onClick={() => handlePlay(params?.row?._id)}>
                <PlayCircleFilledWhiteOutlinedIcon
                  sx={{ color: "#001A72", fontSize: 20 }}
                />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteOpen(params?.row?._id)}>
              <DeleteOutlinedIcon sx={{ color: "#D60000", fontSize: 20 }} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const { data, isLoading, isRefetching, refetch } = useQuery(
    ["audio-get", filterData],
    () =>
      instance.get(
        adminCallBlasting.admin_call_blasting_audio +
          "?" +
          qs.stringify(filterData)
      )
  );

  const { isLoading: audioIsLoading, mutate: audioMutate } = useMutation(
    ["audio-play"],
    (audio) =>
      instance.get(
        adminCallBlasting.admin_call_blasting_audio_play + `/${audio}`,
        { responseType: "blob" }
      ),
    {
      onSuccess: (response) => {
        const blob = response?.data;
        const audioUrl = URL.createObjectURL(blob);
        setAudioSrc(audioUrl);
      },
      onError: () => {
        setSnackbarData({
          open: true,
          severity: "error",
          message: "Error Playing Audio!",
        });
        setSelectedAudioId(null);
      },
    }
  );

  const uploadAudio = useMutation(
    (audio) =>
      instance.post(adminCallBlasting.admin_call_blasting_audio_upload, audio),
    {
      onSuccess: (response) => {
        setSnackbarData({
          open: true,
          severity: "success",
          message: "Audio Uploaded!",
        });
        refetch();
      },
      onError: () => {
        setSnackbarData({
          open: true,
          severity: "error",
          message: "Error Uploading Audio!",
        });
      },
    }
  );

  const deleteAudio = useMutation(
    ["delete-audio"],
    (audio) =>
      instance.delete(
        adminCallBlasting.admin_call_blasting_audio + `/${audio}`
      ),
    {
      onSuccess: (response) => {
        setSnackbarData({
          open: true,
          severity: "success",
          message: "Audio Deleted!",
        });
        refetch();
        handleDeleteClose();
      },
      onError: () => {
        setSnackbarData({
          open: true,
          severity: "error",
          message: "Audio Not Deleted",
        });
      },
    }
  );

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleFromDate = (value) => {
    setFilterData({
      ...filterData,
      startDate: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleToDate = (value) => {
    setFilterData({
      ...filterData,
      endDate: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleDelete = () => {
    deleteAudio.mutate(deleteRowId);
  };

  const handleUpload = () => {
    const formData = new FormData();
    if (selectedFile) {
      formData.append("file", selectedFile);
      uploadAudio.mutate(formData);
      setSelectedFile(null);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarData({ ...snackbarData, open: false });
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleDeleteOpen = (audioId) => {
    setDeleteRowId(audioId);
    setOpenDelete(true);
  };

  const handlePlay = (audioId) => {
    setSelectedAudioId(audioId);
    audioMutate(audioId);
  };

  return (
    <Box
      sx={{
        height: "100vh",
        overflowY: "hidden",
        overflowX: "hidden",
        marginBottom: 2,
        mt: 1,
      }}
    >
      <Suspense fallback={<FullpageLoader open={deleteAudio.isLoading} />}>
        {openDelete && (
          <ConfirmDialogBox
            open={openDelete}
            handleClose={handleDeleteClose}
            handleConfirm={handleDelete}
            label={
              "Are you sure you want to delete this? This action cannot be undone."
            }
            isLoading={deleteAudio.isLoading}
          />
        )}
      </Suspense>
      <FullpageLoader
        open={
          isLoading || isRefetching || uploadAudio.isLoading || audioIsLoading
        }
      />
      <SnackbarComponent
        data={snackbarData}
        open={snackbarData.open}
        handleClose={handleSnackbarClose}
      />
      <Box sx={{ mb: 1, px: 2 }}>
        <Typography variant="h6">Upload Audio</Typography>
        <Box display={"flex"} sx={{ justifyContent: "center" }}>
          <Card
            variant="outlined"
            sx={{
              justifyContent: "center",
              backgroundColor: "rgba(35, 35, 156, 0.05)",
              height: "20vh",
              alignItems: "center",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                px: "12px",
                pt: "12px",
                pb: 0,
              }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {!selectedFile ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <CardMedia
                    component={"img"}
                    sx={{ height: 40, width: 40 }}
                    image="/feather_upload-cloud.png"
                    alt="upload-icon"
                  />
                  <Typography variant="body2">
                    Select a file or Drag and Drop Here
                  </Typography>
                </Box>
              ) : (
                <Paper
                  sx={{
                    display: "flex",
                    p: 1,
                    borderRadius: 2,
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 1,
                  }}
                >
                  <IconButton onClick={handleButtonClick}>
                    <PlaylistPlayIcon sx={{ color: "#23239C" }} />
                  </IconButton>
                  <Typography
                    color="text.secondary"
                    sx={{ mr: 2, fontSize: 14 }}
                  >
                    {selectedFile.name}
                  </Typography>
                </Paper>
              )}
            </CardContent>
            <CardActions
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={handleFileChange}
                ref={fileInputRef}
                accept="audio/mpeg, audio/wav, audio/mp3"
              />
              {!selectedFile ? (
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleButtonClick}
                  sx={{
                    backgroundColor: "#23239C",
                    boxShadow: "0 0 0 0.2px rgba(35, 35, 156, 0.7)",
                    "&:hover": {
                      backgroundColor: alpha("#23239C", 0.85),
                    },
                    justifyContent: "flex-end",
                  }}
                >
                  <PlaylistPlayIcon />
                  Browse
                </Button>
              ) : (
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleUpload}
                  sx={{
                    backgroundColor: "#23239C",
                    boxShadow: "0 0 0 0.2px rgba(35, 35, 156, 0.7)",
                    "&:hover": {
                      backgroundColor: alpha("#23239C", 0.85),
                    },
                  }}
                >
                  <UploadIcon />
                  Upload
                </Button>
              )}
            </CardActions>
          </Card>
        </Box>
      </Box>
      <Box>
        <Box
          display={"flex"}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          marginTop={1}
          px={2}
        >
          <Typography variant="h6">Uploaded List</Typography>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ width: 350 }}
          >
            <Box sx={{ m: 1 }}>
              <DatePicker
                label={"From"}
                value={filterData?.startDate}
                handleChange={handleFromDate}
                name="from"
              />
            </Box>
            <Box sx={{ m: 1 }}>
              <DatePicker
                label={"To"}
                value={filterData?.endDate}
                handleChange={handleToDate}
                name="to"
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ ml: 2, mr: 2 }}>
          {data?.data?.data ? (
            <CustomDataGrid
              getRowId={(row) => row._id}
              rows={data?.data?.data ?? []}
              columns={columns}
              height={"42vh"}
            />
          ) : (
            <Typography variant="body2" sx={{ textAlign: "center" }}>
              No Data Available
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AudioManagement;
