import {
  Alert,
  Box,
  Card,
  CardContent,
  Checkbox,
  Snackbar,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useMutation, useQuery } from "@tanstack/react-query";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import ConfirmDialogBox from "app/components/Shared/ConfirmDialogBox/ConfirmDialogBox";
import CustomScrollBarBox from "app/components/Shared/CustomScrollBarBox/CustomScrollBarBox";
import CustomPagination from "app/shared/Pagination/Pagination.component";
import { agentAbandonedCallReport } from "app/utils/api-urls/AgentApiUrls";
import { instance } from "app/utils/axiosRequest";
import dayjs from "dayjs";
import qs from "qs";
import { useState } from "react";

const AbandonedCallReport = () => {
  const [filterData, setFilterData] = useState({
    page: 1,
    limit: 10,
  });

  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [snackbarData, setSnackbarData] = useState({
    open: false,
    severity: "error",
    message: "",
  });

  const columns = [
    { field: "source", headerName: "Source", flex: 1 },
    {
      field: "destination",
      headerName: "Destination",
      flex: 1,
      renderCell: (params) => {
        const destination = params?.row?.destination;
        return destination ? destination?.extension || "" : "";
      },
    },
    {
      field: "queue",
      headerName: "Queue",
      flex: 1,
      renderCell: (params) => params?.row?.queue?.name || "",
    },
    {
      field: "holdTime",
      headerName: "Hold Time",
      flex: 1,
      renderCell: (params) => `${params?.value} sec`,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      renderCell: (params) =>
        dayjs(params?.row?.createdAt).format("D MMMM YYYY"),
    },
    {
      field: "time",
      headerName: "Time",
      flex: 1,
      renderCell: (params) => dayjs(params?.row?.createdAt).format("hh:mm A"),
    },
    {
      field: "isFollowedUp",
      headerName: "Followed Up",
      flex: 1,
      renderCell: (params) => (
        <Typography
          variant="body1"
          color={params?.row?.isFollowedUp ? "success.main" : "error.main"}
        >
          {params?.row?.isFollowedUp ? "Yes" : "No"}
        </Typography>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Checkbox
          checked={params?.row?.isFollowedUp}
          onChange={(event) => handleCheckboxClick(event, params.row)}
        />
      ),
    },
  ];

  const { data, isLoading, refetch } = useQuery(
    ["agent-abandoned-call", filterData],
    () =>
      instance.get(
        agentAbandonedCallReport.agent_abandoned_call_report_get +
          "?" +
          qs.stringify(filterData)
      )
  );

  const { mutate: updateFollowedUpStatus } = useMutation(
    (updated) =>
      instance.patch(
        agentAbandonedCallReport.agent_abandoned_call_report_patch +
          `/${updated?._id}`,
        { isFollowedUp: updated?.isFollowedUp }
      ),
    {
      onSuccess: () => {
        setSnackbarData({
          open: true,
          severity: "success",
          message: "Updated successfully",
        });
        handleConfirmClose();
        refetch();
      },
      onError: () => {
        setSnackbarData({
          open: true,
          severity: "error",
          message: "Error Updating",
        });
      },
    }
  );

  // handle to close the confirm dialog box
  const handleConfirmClose = () => {
    setOpenConfirm(false);
    setSelectedRow(null);
  };

  //  closes the snackbar handler
  const handleSnackbarClose = () => {
    setSnackbarData({ ...snackbarData, open: false });
  };

  //pagination left click
  const handleLeftClick = () => {
    setFilterData({
      ...filterData,
      page: --filterData.page,
    });
    refetch();
  };
  //pagination right click
  const handleRightClick = () => {
    setFilterData({
      ...filterData,
      page: ++filterData.page,
    });
    refetch();
  };

  const handleFirstPageClick = () => {
    setFilterData({
      ...filterData,
      page: 1,
    });
    refetch();
  };

  const handleLastPageClick = () => {
    setFilterData({
      ...filterData,
      page: data?.data?.totalPages,
    });
    refetch();
  };

  const handlePageClick = (page) => {
    setFilterData((prevState) => ({
      ...prevState,
      page: page,
    }));
    refetch();
  };

  // handler for the state of the checkbox
  // handler for the state of the checkbox
  const handleCheckboxClick = (event, row) => {
    setOpenConfirm(true); // Open the confirmation dialog regardless of checkbox state
    setSelectedRow(row); // Store the selected row in state
  };

  const handleConfirmDialog = (confirmed) => {
    setOpenConfirm(false); // Close the confirmation dialog
    if (confirmed && selectedRow) {
      selectedRow.isFollowedUp = !selectedRow.isFollowedUp; // Toggle isFollowedUp status
      updateFollowedUpStatus(selectedRow); // Update the followed-up status in the backend
    }
  };

  return (
    <>
      <FullpageLoader open={isLoading} />
      <Card sx={{ height: "77vh", borderRadius: 3, mt: 1 }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              px: 1,
              pb: 2,
            }}
          >
            <Box
              display="flex"
              gap="5px"
              padding="1px"
              color="rgba(19, 19, 19, 0.7)"
              borderRadius={2}
              border="2px solid rgba(19, 19, 19, 0.5)"
              width="10vw"
              justifyContent="center"
              alignItems={"center"}
              height={"5vh"}
            >
              Total Data: {data?.data?.totalData}
            </Box>
          </Box>
          <Box sx={{ border: "1px solid #d3d3d3", borderRadius: "4px" }}>
            <CustomScrollBarBox
              height={"60vh"}
              scrollbarWidth={"4px"}
              mb={0}
              p={0}
            >
              <DataGrid
                getRowId={(row) => row?._id}
                rows={data?.data?.data || []}
                columns={columns}
                hideFooter
                autoHeight
              />
            </CustomScrollBarBox>
          </Box>
          <CustomPagination
            currentPage={filterData?.page}
            totalPage={data?.data?.totalPages}
            handleLeftClick={handleLeftClick}
            handleRightClick={handleRightClick}
            handleFirstPageClick={handleFirstPageClick}
            handleLastPageClick={handleLastPageClick}
            handlePageClick={handlePageClick}
          />
        </CardContent>
        {openConfirm && (
          <ConfirmDialogBox
            open={openConfirm}
            handleClose={handleConfirmClose}
            handleConfirm={handleConfirmDialog}
            label={"Are you sure you want to perform this action?"}
          />
        )}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={snackbarData?.open}
          autoHideDuration={1000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarData?.severity}
            sx={{ width: "100%" }}
          >
            {snackbarData?.message}
          </Alert>
        </Snackbar>
      </Card>
    </>
  );
};

export default AbandonedCallReport;
