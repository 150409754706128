import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React from "react";

const UpdateActionPage = ({
  duringCallValue,
  handleStatusChange,
  hasTicketLogs,
  statusOptions,
  handleRootChange,
  rootOptions,
  resolutionOptions,
  root,
  resolution,
  handleResolutionChange,
  handleSelectAgent,
  handleSelectQueue,
}) => {
  return (
    <>
      <FormControl component="fieldset">
        <Box display="flex" flexDirection="column" gap=".5em">
          <RadioGroup
            row
            aria-label="status"
            name="status"
            value={duringCallValue?.status}
            onChange={handleStatusChange}
          >
            <FormControlLabel
              value={
                hasTicketLogs
                  ? statusOptions?.closed
                  : statusOptions?.raised_closed
              }
              control={<Radio />}
              label={hasTicketLogs ? "Closed" : "Raised & Closed"}
              sx={{ mr: "5rem" }}
            />
            <FormControlLabel
              value={statusOptions?.assign}
              control={<Radio />}
              label="Assign"
            />
          </RadioGroup>
          {(duringCallValue?.status === statusOptions?.raised_closed ||
            duringCallValue?.status === statusOptions?.closed) && (
            <Box
              display="flex"
              flexDirection="row"
              gap="1em"
              alignItems="center"
            >
              <FormControl sx={{ mr: "2rem", width: "250px" }}>
                <InputLabel id="root-label">Root Cause</InputLabel>
                <Select
                  labelId="root-label"
                  id="root"
                  value={root}
                  sx={{
                    borderRadius: "12px",
                    backgroundColor: "#F5F5F5",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&:hover": {
                      backgroundColor: "#E0E0E0",
                    },
                    "&.Mui-focused": {
                      backgroundColor: "#F5F5F5",
                    },
                  }}
                  label="Root Cause"
                  onChange={handleRootChange}
                >
                  {rootOptions?.map((option) => (
                    <MenuItem key={option.id} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {root && (
                <FormControl sx={{ mr: "2rem", width: "250px" }}>
                  <InputLabel id="resolution-label">Resolution</InputLabel>
                  <Select
                    labelId="resolution-label"
                    id="resolution"
                    sx={{
                      borderRadius: "12px",
                      backgroundColor: "#F5F5F5",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                      },
                      "&:hover": {
                        backgroundColor: "#E0E0E0",
                      },
                      "&.Mui-focused": {
                        backgroundColor: "#F5F5F5",
                      },
                    }}
                    value={resolution}
                    label="Resolution"
                    onChange={handleResolutionChange}
                  >
                    {resolutionOptions.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
          )}
          {duringCallValue?.status === statusOptions?.assign && (
            <Box
              display="flex"
              flexDirection="row"
              gap="1em"
              alignItems="center"
            >
              <FormControl sx={{ width: "250px" }}>
                <InputLabel id="department-label">Select Department</InputLabel>
                <Select
                  labelId="department-label"
                  id="department"
                  onChange={handleSelectQueue}
                  label="Select Department"
                  sx={{
                    borderRadius: "12px",
                    backgroundColor: "#F5F5F5",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&:hover": {
                      backgroundColor: "#E0E0E0",
                    },
                    "&.Mui-focused": {
                      backgroundColor: "#F5F5F5",
                    },
                  }}
                >
                  {duringCallValue?.queues?.map((option) => (
                    <MenuItem key={option.id} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {duringCallValue?.selectedQueue && (
                <FormControl sx={{ width: "250px" }}>
                  <InputLabel id="root-label">Select Agent</InputLabel>
                  <Select
                    labelId="agent-label"
                    id="agent"
                    onChange={handleSelectAgent}
                    label="Select Agent"
                    sx={{
                      borderRadius: "12px",
                      backgroundColor: "#F5F5F5",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                      },
                      "&:hover": {
                        backgroundColor: "#E0E0E0",
                      },
                      "&.Mui-focused": {
                        backgroundColor: "#F5F5F5",
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {duringCallValue?.agents?.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
          )}
        </Box>
      </FormControl>
    </>
  );
};

export default UpdateActionPage;
