import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const SelectComponent = ({
  options,
  label,
  value,
  handleChange,
  name,
  required,
  disabled
}) => {
  return (
    <>
      <FormControl fullWidth>
        <InputLabel size="small">
          {label}
        </InputLabel>
        <Select
          required={required}
          value={value}
          label={label}
          onChange={handleChange}
          disabled={disabled}
          size="small"
          name={name}
          MenuProps={MenuProps}
        >
          {options?.map((option) => {
            return (
              <MenuItem key={option?.id} value={option?.value}>
                {option?.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

export default SelectComponent;
