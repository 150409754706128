import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { DeleteOutline, Edit } from "@mui/icons-material";
import ConfirmDialogBox from "app/components/Shared/ConfirmDialogBox/ConfirmDialogBox";
import { adminRootCauseandResolution } from "app/utils/api-urls/AdminAPiUrls";
import { useMutation } from "@tanstack/react-query";
import { instance } from "app/utils/axiosRequest";
import SnackbarComponent from "app/components/SnackBar";

const GetRootCauseandResolution = ({ rootCauses, openEditModal, refetch }) => {
  const [cardData, setCardData] = useState([]);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [snackbarData, setSnackbarData] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const { mutate: deleteRootCause, isLoading } = useMutation(
    ["delete-root-cause"],
    () =>
      instance.delete(
        `${adminRootCauseandResolution.admin_root_caue_and_resolution}/${selectedData?._id}`
      ),
    {
      onSuccess: () => {
        handleDeleteClose();
        setSelectedData(null);
        refetch();
        setSnackbarData({
          open: true,
          severity: "success",
          message: "Root Cause Deleted successfully!",
        });
      },
      onError: () => {
        setSnackbarData({
          open: true,
          severity: "error",
          message: "Error while deleting rootcause ! please try again later",
        });
      },
    }
  );

  const chunkArray = (array, size) => {
    const chunkedArr = [];
    let index = 0;
    while (index < array.length) {
      chunkedArr.push(array.slice(index, size + index));
      index += size;
    }
    return chunkedArr;
  };

  const cardDataChunks = chunkArray(cardData, 3);

  useEffect(() => {
    rootCauses && setCardData(rootCauses);
  }, [rootCauses]);

  const handleEdit = (data) => {
    openEditModal(data);
  };

  const handleDeleteOpen = (data) => {
    setSelectedData(data);
    setIsConfirmOpen(true);
  };

  const handleDeleteClose = () => {
    setIsConfirmOpen(false);
  };

  const handleDeleteConfirm = () => {
    setIsConfirmOpen(false);
    deleteRootCause();
  };

  const handleSnackbarClose = () => {
    setSnackbarData((prev) => ({ ...prev, open: false }));
  };

  return (
    <>
      <SnackbarComponent
        open={snackbarData.open}
        handleClose={handleSnackbarClose}
        data={snackbarData}
      />
      {cardDataChunks?.map((chunk, chunkIndex) => (
        <Box
          key={chunkIndex}
          marginTop={chunkIndex === 0 ? "1rem" : "2rem"}
          marginBottom="1rem"
          display="flex"
          flexDirection="row"
          gap="4rem"
        >
          {chunk?.map((data, index) => (
            <Card key={index} sx={{ width: "30%", height: "350px" }}>
              <CardContent
                sx={{
                  height: "340px",
                  scrollbarWidth: "thin",
                  scrollbarColor: "#888 #f1f1f1",
                  "&::-webkit-scrollbar": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                    borderRadius: "8px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#888",
                    borderRadius: "8px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                  },
                  overflowY: "auto",
                }}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography variant="h6" fontSize="18px">
                    {data?.name}
                  </Typography>
                  <Box>
                    <Tooltip title="Edit Root Cause">
                      <IconButton onClick={() => handleEdit(data)}>
                        <Edit sx={{ color: "#FFA726" }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Root Cause">
                      <IconButton onClick={() => handleDeleteOpen(data)}>
                        <DeleteOutline sx={{ color: "#FF6969" }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>

                <Divider sx={{ marginTop: "0.5rem" }} />
                <Typography variant="caption" color="#607274" fontSize="15px">
                  Resolution
                </Typography>
                {data?.resolutions?.map((resolution, subIndex) => (
                  <>
                    {" "}
                    <Box
                      key={subIndex}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "0.5rem",
                      }}
                    >
                      <Typography
                        variant="span"
                        fontWeight="bold"
                        fontSize="15px"
                      >
                        {resolution?.name}
                      </Typography>
                    </Box>
                    <Divider sx={{ marginTop: "2rem" }} />
                  </>
                ))}
              </CardContent>
            </Card>
          ))}
        </Box>
      ))}
      <ConfirmDialogBox
        open={isConfirmOpen}
        handleClose={handleDeleteClose}
        handleConfirm={handleDeleteConfirm}
        label="Are you sure you want to delete this root cause?"
      />
    </>
  );
};

export default GetRootCauseandResolution;
