import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
  alpha,
} from "@mui/material";

const ConfirmDialogBox = ({
  open,
  handleClose,
  handleConfirm,
  label,
  isLoading,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          width: "22rem",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: "1 4px 6px rgba(35, 35, 156, 0.2)",
          bgcolor: "background.paper",
          p: 4,
          borderRadius: 2,
          textAlign: "center",
        }}
      >
        <Typography sx={{ fontSize: 18, fontWeight: "medium" }}>
          {label}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "bottom",
          }}
        >
          <Button
            variant="contained"
            sx={{
              mt: 2,
              mx: 1,
              backgroundColor: "#23239C",
              "&:hover": {
                backgroundColor: alpha("#23239C", 0.85),
              },
            }}
            onClick={handleConfirm}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={18} color="inherit" />
            ) : (
              "Confirm"
            )}
          </Button>
          <Button
            variant="outlined"
            color="error"
            sx={{
              mt: 2,
              mx: 1,
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmDialogBox;
