import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useHistory } from "react-router-dom";

const RecentActivity = ({ TicketDataByPhone }) => {
  const history = useHistory();
  const handleActionClick = (ticketId) => {
    history.push(`/app/v2/create-ticket/${ticketId}`);
  };

  return (
    <>
      <Card
        sx={{
          boxShadow: "4px 2px 24px 0px #23239C33",
          borderRadius: "12px",
          height: "70vh",
          width: "100%",
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              mb: "1rem",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">Recent Tickets</Typography>
          </Box>
          <Box
            sx={{
              overflowY: "auto",
              maxHeight: "55vh",
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 5px grey",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#DDDDDD",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#1b296d",
              },
            }}
          >
            {TicketDataByPhone && TicketDataByPhone.length > 0 ? (
              TicketDataByPhone?.map((item, index) => (
                <Box
                  key={index}
                  sx={{ mr: 1 }}
                  display="flex"
                  flexDirection="column"
                  gap=".5em"
                >
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      variant="span"
                      fontWeight="medium"
                      fontSize="16px"
                    >
                      {dayjs(item?.createdAt).format("YYYY/MM/DD")} |{" "}
                      {dayjs(item?.createdAt).format("HH:mm:ss")}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: ".5em",
                      }}
                    >
                      <Typography
                        variant="span"
                        fontWeight="medium"
                        fontSize="18px"
                      >
                        Status:
                      </Typography>
                      <Typography
                        variant="span"
                        sx={{
                          textTransform: "uppercase",
                          color:
                            item.statusId.name === "closed"
                              ? "#008000"
                              : item.statusId.name === "assigned"
                              ? "#D49B54"
                              : "#008000",
                        }}
                        fontWeight="bold"
                        fontSize="16px"
                      >
                        {item.statusId?.name}
                      </Typography>
                    </Box>
                  </Box>
                  {/* Issue and Sub-Issue */}
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="body2">Assigned To:</Typography>
                    <Typography fontWeight="bold" variant="body2">
                      {item?.assignedTo
                        ? `${item.assignedTo?.firstName}(${item.assignedTo?.extension}) - ${item.assignedDepartment?.name}`
                        : item.assignedDepartment?.name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", flexDirection: "row", gap: "4em" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: ".5em",
                      }}
                    >
                      <Typography variant="span">Issue:</Typography>
                      <Typography fontWeight="bold" variant="span">
                        {item.issueId?.name}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: ".5em",
                      }}
                    >
                      <Typography variant="span">Sub-Issue:</Typography>
                      <Typography fontWeight="bold" variant="span">
                        {item.subIssueId?.name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ width: "60%", mr: "1em", overflow: "hidden" }}>
                      <Typography variant="span">Remarks:</Typography>
                      <Tooltip
                        title={item?.remarks}
                        placement="bottom-start"
                        arrow
                      >
                        <Typography
                          variant="span"
                          sx={{
                            fontWeight: "bold",
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                          }}
                        >
                          {item?.remarks}
                        </Typography>
                      </Tooltip>
                    </Box>
                    <Button
                      variant="contained"
                      onClick={() => handleActionClick(item._id)}
                      sx={{
                        minWidth: "70px",
                        borderRadius: "12px",
                        textTransform: "none",
                        height: "35px",
                        padding: "4px 8px",
                        fontSize: ".8rem",
                        backgroundColor: "#23239C",
                        "&:hover": {
                          backgroundColor: "#1b296d",
                        },
                      }}
                    >
                      {(item?.statusId?.name === "raised and closed" ||
                        item?.statusId?.name === "closed") &&
                      item.action.includes("update")
                        ? "Re-open Ticket"
                        : item.action.includes("update")
                        ? "Update Ticket"
                        : item.action.includes("view")
                        ? "Add Comment"
                        : "View"}
                    </Button>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                </Box>
              ))
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  height: "100%",
                }}
              >
                <Typography variant="subtitle1" textAlign="center">
                  No Recent Tickets !
                </Typography>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default RecentActivity;
