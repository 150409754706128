import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import DatePicker from "app/components/Shared/DatePicker/DatePicker.component";
import SelectComponent from "app/components/Shared/Select/Select.component";
import { adminCallBlasting } from "app/utils/api-urls/AdminAPiUrls";
import { instance } from "app/utils/axiosRequest";
import dayjs from "dayjs";
import qs from "qs";
import { lazy, useState } from "react";
import CreateCampaign from "./CreateCampaign";
import CustomDataGrid from "app/components/Shared/DataGrid/CustomDataGrid";

const SnackbarComponent = lazy(() => import("app/components/SnackBar"));
const ConfirmDialogBox = lazy(() =>
  import("app/components/Shared/ConfirmDialogBox/ConfirmDialogBox")
);

const CampaignManagement = () => {
  const [filterData, setFilterData] = useState({
    startDate: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  });
  const [showCreateCampaignTab, setShowCreateCampaignTab] = useState(false);
  const [mode, setMode] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [snackbarData, setSnackbarData] = useState({
    open: false,
    severity: "error",
    message: "",
  });

  const [openDelete, setOpenDelete] = useState(false);

  const columns = [
    {
      field: "campaignName",
      headerName: "Campaign Name",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params?.row?.campaignName}>
          <Typography
            variant={"body"}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {params?.row?.campaignName}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "scheduleDate",
      headerName: "Created Date",
      flex: 1,
      renderCell: (params) =>
        dayjs(params?.row?.scheduleDate).format("YYYY-MM-DD"),
    },
    {
      field: "endDate",
      headerName: "Ending Date",
      flex: 1,
      renderCell: (params) => dayjs(params?.row?.endDate).format("YYYY-MM-DD"),
    },
    {
      field: "audio",
      headerName: "Audio",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params?.row?.audio?.name}>
          <Typography
            variant={"body"}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {params?.row?.audio?.name}
          </Typography>
        </Tooltip>
      ),
    },
    { field: "trunk", headerName: "Trunk", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{
            color:
              params?.value === "Running"
                ? "#4E9F3D"
                : params?.value === "Stop"
                ? "#D60000"
                : "#EFCB63",
          }}
          textTransform={"capitalize"}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tooltip title="View">
            <IconButton onClick={handleViewCampaignClick}>
              <VisibilityOutlinedIcon sx={{ color: "#001A72", fontSize: 20 }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton onClick={handleEditCampaignClick}>
              <EditOutlinedIcon sx={{ color: "#001A72", fontSize: 20 }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={handleDeleteOpen}>
              <DeleteOutlinedIcon sx={{ color: "#D60000", fontSize: 20 }} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const { data, isLoading, isRefetching, refetch } = useQuery(
    ["campaigns", filterData],
    () =>
      instance.get(
        adminCallBlasting.admin_call_blasting_campaign_api +
          "?" +
          qs.stringify(filterData)
      )
  );

  const {
    data: audioData,
    isLoading: audioLoading,
    refetch: audioFetch,
  } = useQuery(
    ["admin-campaign-audio-get"],
    () => instance.get(adminCallBlasting.admin_call_blasting_audio),
    { enabled: false }
  );

  const audio =
    audioData?.data?.data.map((item) => ({
      id: item._id,
      label: item.name,
      value: item._id,
    })) ?? [];

  const deleteCampaign = useMutation(
    ["delete-campaign"],
    (campaign) =>
      instance.delete(
        adminCallBlasting.admin_call_blasting_campaign_api + `/${campaign?._id}`
      ),
    {
      onSuccess: (response) => {
        refetch();
        setSnackbarData({
          open: true,
          severity: "success",
          message: "Campaign Deleted!",
        });
        setOpenDelete(false);
      },
      onError: () => {
        setSnackbarData({
          open: true,
          severity: "error",
          message: "Campaign Not Deleted",
        });
      },
    }
  );

  const handleFromDate = (value) => {
    setFilterData({
      ...filterData,
      startDate: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleToDate = (value) => {
    setFilterData({
      ...filterData,
      endDate: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleCreateCampaignClick = () => {
    setShowCreateCampaignTab(true);
    setMode("create");
    audioFetch();
  };

  const handleEditCampaignClick = (campaign) => {
    setShowCreateCampaignTab(true);
    setSelectedRow(campaign);
    setMode("edit");
    audioFetch();
  };

  const handleViewCampaignClick = (campaign) => {
    setSelectedRow(campaign);
    setShowCreateCampaignTab(true);
    setMode("view");
    audioFetch();
  };

  const handleSnackbarClose = () => {
    setSnackbarData({ ...snackbarData, open: false });
  };

  const handleDeleteCampaignClick = () => {
    deleteCampaign.mutate(selectedRow);
  };

  const handleBack = () => {
    setShowCreateCampaignTab(false);
  };

  const handleRefetch = () => {
    refetch();
  };

  const handleSetSnackbarData = (newSnackbarData) => {
    setSnackbarData(newSnackbarData);
  };

  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  return (
    <Box sx={{ height: "100vh", marginBottom: 2 }}>
      {isLoading && <FullpageLoader open={isLoading || isRefetching} />}
      {openDelete && (
        <ConfirmDialogBox
          open={openDelete}
          handleClose={handleDeleteClose}
          handleConfirm={handleDeleteCampaignClick}
          label={
            "Are you sure you want to delete this campaign? This action cannot be undone."
          }
          isLoading={deleteCampaign.isLoading}
        />
      )}
      {showCreateCampaignTab ? (
        <CreateCampaign
          campaign={selectedRow}
          onBack={handleBack}
          mode={mode}
          handleRefetch={handleRefetch}
          handleSnackbarClose={handleSnackbarClose}
          snackbarData={snackbarData}
          handleSetSnackbarData={handleSetSnackbarData}
          open={snackbarData.open}
          audioData={audio}
          audioLoading={audioLoading}
        />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            display={"flex"}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" sx={{ ml: 2 }}>
              Campaign Management
            </Typography>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              sx={{ justifyItems: "center" }}
            >
              {/* <Box sx={{ m: 1, minWidth: 60 }}>
                <SelectComponent options={filterOptions} label="All" />
              </Box> */}
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                sx={{ width: 350 }}
              >
                <Box sx={{ m: 1 }}>
                  <DatePicker
                    label={"From"}
                    value={filterData?.startDate}
                    handleChange={handleFromDate}
                    name="from"
                  />
                </Box>
                <Box sx={{ m: 1 }}>
                  <DatePicker
                    label={"To"}
                    value={filterData?.endDate}
                    handleChange={handleToDate}
                    name="to"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  mt: 1.5,
                  mr: 2,
                  ml: 0.5,
                  cursor: "pointer",
                }}
                onClick={handleCreateCampaignClick}
              >
                <ControlPointOutlinedIcon
                  sx={{ width: 30, height: 30, color: "#23239C" }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              mx: 2,
              justifyContent: "space-around",
            }}
          >
            {data?.data?.data ? (
              <CustomDataGrid
                columns={columns}
                rows={data?.data?.data}
                height={"74vh"}
                getRowId={(row) => row?._id}
                onRowClick={(params) => setSelectedRow(params?.row)}
              />
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="70vh"
              >
                <Typography variant="body2">No Campaigns Found</Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
      <SnackbarComponent
        data={snackbarData}
        open={snackbarData.open}
        handleClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default CampaignManagement;
