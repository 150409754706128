
import AgentReport from "app/pages/AgentReports";
import TicketReportV2 from "app/pages/TicketReportV2";
import AbandonedCallReport from "app/pages/reports/abandon-calls-report";

const AgentReportRoutes = [
  {
    path: "/app/report/agentreport",
    shortPath: "/report/agent-report",
    name: "Agent Call Report",
    component: AgentReport,
    icon: "mdi mdi-library",
  },

  {
    path: "/app/ticket/ticket-listV2",
    shortPath: "/ticket/ticket-listV2",
    name: "Agent Ticket Report V2",
    icon: "mdi mdi-clipboard-list-outline",
    component: TicketReportV2,
  },

  {
    path: "/app/report/abandon-calls-report",
    shortPath: "/report/abandon-calls-report",
    name: "Today's Abandoned Call Report",
    component: AbandonedCallReport,
    icon: "mdi mdi-phone-cancel",
  },

  // {
  //   path: "/app/report/dialpad",
  //   shortPath: "/report/dialpad",
  //   name: "Dial pad testing",
  //   component: Dialpad,
  //   icon: "mdi mdi-library",
  // },

  // {
  //   path: "/app/setup/roles",
  //   shortPath: "/setup/roles",
  //   name: "Roles",
  //   component: RoleList,
  //   icon: "mdi mdi-cube-unfolded",
  // },
  // {
  //   path: "/app/setup/permissions",
  //   shortPath: "/setup/permissions",
  //   name: "Permissions",
  //   component: Permissions,
  //   icon: "mdi mdi-account-multiple-check",
  // },
  // {
  //   path: "/app/setup/setup-permissions",
  //   shortPath: "/setup/setup-permissions",
  //   name: "Setup permissions",
  //   component: CreatePermissions,
  //   icon: "mdi mdi-account-multiple-check",
  // },
];

export default AgentReportRoutes;
