import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import CustomScrollBarBox from "app/components/Shared/CustomScrollBarBox/CustomScrollBarBox";
import DatePicker from "app/components/Shared/DatePicker/DatePicker.component";
import SelectComponent from "app/components/Shared/Select/Select.component";
import TimePickerComponent from "app/components/Shared/TimePicker/TimePicker.component";
import { adminCallBlasting } from "app/utils/api-urls/AdminAPiUrls";
import { instance } from "app/utils/axiosRequest";
import dayjs from "dayjs";
import React, { lazy, useEffect, useState } from "react";
import TextfieldStyle from "../../Styles/TextfieldStyle";
import BoxStyle from "../../Styles/BoxStyle";

const SnackbarComponent = lazy(() => import("app/components/SnackBar"));

const CreateCampaign = ({
  onBack,
  campaign,
  mode,
  handleRefetch,
  handleSnackbarClose,
  snackbarData,
  open,
  handleSetSnackbarData,
  audioData,
  audioLoading,
}) => {
  const [selectedLevel, setSelectedLevel] = useState(campaign?.level || 1);

  const [campaignData, setCampaignData] = useState({
    campaignName: "",
    scheduleDate: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
    scheduleTime: dayjs().format("HH:mm"),
    endTime: dayjs().format("HH:mm"),
    trunk: "",
    callAttempt: null,
    timeInterval: null,
    ringTimeout: null,
    level: 1,
    audio: [],
  });

  useEffect(() => {
    if (mode !== "create" && campaign) {
      setCampaignData(campaign);
    }
  }, [mode, campaign]);

  const audioLabels = ["First", "Second", "Third"];

  const statusOptions = [
    {
      id: 1,
      value: "ntc01",
      label: "ntc01",
    },
  ];

  const createCampaign = useMutation(
    ["create-campaign"],
    (newCampaign) =>
      instance.post(
        adminCallBlasting.admin_call_blasting_campaign_create,
        newCampaign
      ),
    {
      onSuccess: (response) => {
        handleSetSnackbarData({
          open: true,
          severity: "success",
          message: "Campaign Created!",
        });
        handleRefetch();
        handleBack();
      },
      onError: (err) => {
        handleSetSnackbarData({
          open: true,
          severity: "error",
          message: "Error creating campaign!",
        });
      },
    }
  );

  const editCampaign = useMutation(
    ["edit-campaign"],
    (campaignData) =>
      instance.patch(
        adminCallBlasting.admin_call_blasting_campaign_api +
          `/${campaignData._id}`,
        campaignData
      ),
    {
      onSuccess: (response) => {
        handleRefetch();
        handleSetSnackbarData({
          open: true,
          severity: "success",
          message: "Campaign Edited!",
        });
        handleBack();
      },
      onError: () => {
        handleSetSnackbarData({
          open: true,
          severity: "error",
          message: "Error editing campaign!",
        });
      },
    }
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "level") {
      setSelectedLevel(parseInt(value));
    } else if (
      name === "callAttempt" ||
      name === "timeInterval" ||
      name === "ringTimeout"
    ) {
      setCampaignData({
        ...campaignData,
        [name]: parseInt(value) || null,
      });
    } else {
      setCampaignData({
        ...campaignData,
        [name]: value,
      });
    }
  };

  const handleSaveData = () => {
    const updatedCampaignData = {
      ...campaignData,
      level: selectedLevel, // Store selectedLevel in campaignData
    };
    // Ensure audio is an array of object ids when in edit mode
    if (mode === "edit") {
      updatedCampaignData.audio = Array.isArray(campaignData?.audio)
        ? campaignData?.audio
        : [campaignData?.audio?._id || campaignData?.audio]; // assuming audio object has an _id field
    }

    setCampaignData(updatedCampaignData); // Update campaignData with the selected level
    if (mode === "create") {
      createCampaign.mutate(updatedCampaignData); // Call createCampaign mutation with updated campaignData
    } else {
      editCampaign.mutate(updatedCampaignData); // Call editCampaign mutation with updated campaignData
    }
  };

  const handleRadioChange = (level) => {
    setSelectedLevel(level);
    setCampaignData({
      ...campaignData,
      level: parseInt(level),
    });
  };

  const handleFromDate = (value) => {
    setCampaignData({
      ...campaignData,
      scheduleDate: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleToDate = (value) => {
    setCampaignData({
      ...campaignData,
      endDate: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleFromTime = (event) => {
    const formattedTime = dayjs(event.target.value, "HH:mm").format("HH:mm");
    setCampaignData({
      ...campaignData,
      scheduleTime: formattedTime,
    });
  };

  const handleToTime = (event) => {
    const formattedTime = dayjs(event.target.value, "HH:mm").format("HH:mm");
    setCampaignData({
      ...campaignData,
      endTime: formattedTime,
    });
  };

  const handleBack = () => {
    onBack();
  };

  const handleSelectChange = (event) => {
    const selectedAudioId = event.target.value;
    setCampaignData({
      ...campaignData,
      audio: [selectedAudioId],
    });
  };
  return (
    <>
      <SnackbarComponent
        data={snackbarData}
        open={open}
        handleClose={handleSnackbarClose}
      />
      <FullpageLoader
        open={
          createCampaign.isLoading || editCampaign.isLoading || audioLoading
        }
      />
      <Box sx={{ height: "85vh", overflowY: "hidden" }}>
        <CustomScrollBarBox height={"75vh"} scrollbarWidth={"6px"} p={2}>
          <Box sx={{ display: "flex", justifyItems: "center" }}>
            <ArrowBackIosNewOutlinedIcon
              sx={{ mr: 2, mt: 0.5, cursor: "pointer", color: "#23239C" }}
              onClick={handleBack}
            />
            {/* Heading Create Campaign */}
            <Typography variant="h6" sx={{ mb: 1 }}>
              {mode === "create"
                ? "Create Campaign"
                : mode === "edit"
                ? "Edit Campaign"
                : "View Campaign"}
            </Typography>
          </Box>
          <Box>
            {/* Campaign Name field */}
            <Box sx={BoxStyle(mode)}>
              <Typography sx={{ alignItems: "center", m: 1, fontSize: 15 }}>
                Campaign Name
              </Typography>
              <Box sx={{ mr: 1, width: 310 }}>
                <TextField
                  value={campaignData?.campaignName}
                  onChange={handleChange}
                  name="campaignName"
                  disabled={mode === "view"}
                  size="small"
                  sx={TextfieldStyle}
                />
              </Box>
            </Box>
            <Box>
              {/* Level Radio Buttons */}
              <Box sx={BoxStyle(mode)}>
                <Typography sx={{ m: 1, alignItems: "center", fontSize: 15 }}>
                  Level
                </Typography>
                <Box
                  sx={{ display: "flex", mr: 1, alignItems: "center", mt: 1 }}
                >
                  <RadioGroup
                    value={selectedLevel}
                    onChange={(event) =>
                      handleRadioChange(parseInt(event.target.value))
                    }
                    sx={{ display: "flex", flexDirection: "row" }}
                  >
                    {[1, 2, 3].map((level, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked": {
                                color: alpha("#23239C", 0.9),
                              },
                            }}
                          />
                        }
                        label={level.toString()}
                        value={level}
                        disabled={mode === "view"}
                      />
                    ))}
                  </RadioGroup>
                </Box>
              </Box>
              {/* Select Audio */}
              {selectedLevel !== null && (
                <Box sx={{ mb: 2, mt: 1 }}>
                  <Typography>
                    {audioLabels.slice(0, selectedLevel).map((label, index) => (
                      <Box key={index} sx={BoxStyle(mode)}>
                        <Typography sx={{ m: 1, fontSize: 15 }}>
                          {label + " Audio File"}
                        </Typography>
                        <Box
                          display={"flex"}
                          width={200}
                          sx={{ backgroundColor: "white", m: 1, mr: 2 }}
                        >
                          <SelectComponent
                            options={audioData}
                            label="Select Audio"
                            value={
                              mode !== "create"
                                ? campaignData?.audio?._id ||
                                  campaignData?.audio
                                : campaignData?.audio
                            }
                            name="audio"
                            handleChange={handleSelectChange}
                            disabled={mode === "view"}
                          />
                        </Box>
                      </Box>
                    ))}
                  </Typography>
                </Box>
              )}
            </Box>
            {/* Schedule Date */}
            <Box sx={BoxStyle(mode)}>
              <Typography
                sx={{
                  mb: 1,
                  alignItems: "center",
                  mt: 1,
                  m: 1,
                  fontSize: 15,
                }}
              >
                Schedule Date
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: 320,
                  m: 1,
                }}
              >
                <Box
                  mr={1}
                  sx={{ backgroundColor: "white" }}
                  disabled={mode === "view"}
                >
                  <DatePicker
                    label={"From"}
                    value={campaignData?.scheduleDate}
                    handleChange={handleFromDate}
                    name="scheduleDate"
                    disabled={mode === "view"}
                  />
                </Box>
                <Box
                  mr={1}
                  sx={{ backgroundColor: "white" }}
                  disabled={mode === "view"}
                >
                  <DatePicker
                    label={"To"}
                    value={campaignData?.endDate}
                    handleChange={handleToDate}
                    name="endDate"
                    disabled={mode === "view"}
                  />
                </Box>
              </Box>
            </Box>
            {/* Schedule Time */}
            <Box sx={BoxStyle(mode)}>
              <Typography
                sx={{
                  m: 1,
                  my: 1,
                  alignItems: "center",
                  fontSize: 15,
                }}
              >
                Schedule Time
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: 320,
                  m: 1,
                }}
              >
                <React.Fragment>
                  <Box
                    mr={1}
                    sx={{
                      backgroundColor: "white",
                      width: 152,
                      borderRadius: 1,
                    }}
                  >
                    <TimePickerComponent
                      label={"From"}
                      name="scheduleTime"
                      value={campaignData?.scheduleTime}
                      handleChange={handleFromTime}
                      disabled={mode === "view"}
                    />
                  </Box>
                  <Box
                    mr={1}
                    sx={{
                      backgroundColor: "white",
                      width: 152,
                      borderRadius: 1,
                    }}
                  >
                    <TimePickerComponent
                      label={"To"}
                      name="endTime"
                      value={campaignData?.endTime}
                      handleChange={handleToTime}
                      disabled={mode === "view"}
                    />
                  </Box>
                </React.Fragment>
              </Box>
            </Box>
            {/* Select Trunk */}
            <Box sx={BoxStyle(mode)} disabled={mode === "view"}>
              <SelectComponent
                options={statusOptions}
                label={"Select Trunk"}
                name="trunk"
                handleChange={handleChange}
                value={campaignData?.trunk}
                required={true}
                disabled={mode === "view"}
              />
            </Box>
            {/* No. of Call Attempt field */}
            <Box sx={BoxStyle(mode)}>
              <Typography
                sx={{ m: 1, mb: 1, alignItems: "center", fontSize: 15 }}
              >
                No. of Call Attempt
              </Typography>
              <Box sx={{ mr: 1, width: 100 }}>
                <TextField
                  hiddenLabel
                  disabled={mode === "view"}
                  name="callAttempt"
                  value={campaignData?.callAttempt}
                  onChange={handleChange}
                  size="small"
                  sx={TextfieldStyle}
                />
              </Box>
            </Box>
            {/* Time Interval field */}
            <Box sx={BoxStyle(mode)}>
              <Typography
                sx={{ m: 1, mb: 1, alignItems: "center", fontSize: 15 }}
              >
                Time Interval
              </Typography>
              <Box sx={{ mr: 1, width: 100 }}>
                <FormControl
                  size="small"
                  sx={TextfieldStyle}
                  variant="outlined"
                >
                  <OutlinedInput
                    id="outlined-adornment-min"
                    endAdornment={
                      <InputAdornment position="end">min</InputAdornment>
                    }
                    value={campaignData?.timeInterval}
                    onChange={handleChange}
                    name="timeInterval"
                    disabled={mode === "view"}
                  />
                </FormControl>
              </Box>
            </Box>
            {/* Ring TimeOut Field */}
            <Box sx={BoxStyle(mode)}>
              <Typography
                sx={{ m: 1, mb: 1, alignItems: "center", fontSize: 15 }}
              >
                Ring Timeout
              </Typography>
              <Box sx={{ mr: 1, width: 100 }}>
                <FormControl
                  size="small"
                  sx={TextfieldStyle}
                  variant="outlined"
                >
                  <OutlinedInput
                    id="outlined-adornment-sec"
                    endAdornment={
                      <InputAdornment position="end">sec</InputAdornment>
                    }
                    value={campaignData?.ringTimeout}
                    onChange={handleChange}
                    name="ringTimeout"
                    disabled={mode === "view"}
                  />
                </FormControl>
              </Box>
            </Box>
          </Box>
        </CustomScrollBarBox>
        {/* Save Button */}
        {mode !== "view" && (
          <Box
            sx={{
              display: "flex",
              m: 1,
              mr: 2,
              mt: 2,
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              onClick={handleSaveData}
              disabled={createCampaign.isLoading || editCampaign.isLoading}
              sx={{
                backgroundColor: "#23239C",
                "&:hover": {
                  backgroundColor: alpha("#23239C", 0.85),
                },
              }}
            >
              {createCampaign.isLoading || editCampaign.isLoading ? (
                <CircularProgress size={18} color="inherit" />
              ) : (
                "Save"
              )}
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default CreateCampaign;
