import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import ConfirmDialogBox from "app/components/Shared/ConfirmDialogBox/ConfirmDialogBox";
import dayjs from "dayjs";
import { useState } from "react";
import ChangePasswordModalComponent from "./ChangePasswordModalComponent";

const UserInfoComponent = ({
  userData,
  tabValue,
  onEdit,
  onDelete,
  snackbarData,
  handleSnackbarClose,
  setSnackbarData,
  handleDeleteOpen,
  handleDeleteClose,
  openDelete,
  deleteLoading,
}) => {
  // state to open or close the change password modal
  const [openChangePassword, setOpenChangePassword] = useState(false);
  // state to extend the card to show more data
  const [expanded, setExpanded] = useState(false);

  // edit component called
  const handleEdit = () => {
    onEdit(userData);
  };

  const handleChangePasswordOpen = () => {
    setOpenChangePassword(true);
  };

  const handleChangePasswordClose = () => {
    setOpenChangePassword(false);
  };

  return (
    <Card
      sx={{
        width: "110%",
        borderRadius: 4,
        p: 2,
        height: expanded ? "auto" : "fit",
      }}
    >
      <Box sx={{ m: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ fontWeight: "medium", fontSize: 24, color: "#131313" }}
          >
            {userData.firstName} {userData?.middleName} {userData.lastName}
          </Typography>
          <Box>
            <Tooltip title="Edit">
              <IconButton onClick={handleEdit}>
                <ModeEditOutlineOutlinedIcon sx={{ color: "#001A72" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton onClick={handleDeleteOpen}>
                <DeleteOutlinedIcon sx={{ color: "#D60000" }} />
              </IconButton>
            </Tooltip>
          </Box>
          {openDelete && (
            <ConfirmDialogBox
              open={handleDeleteOpen}
              handleClose={handleDeleteClose}
              handleConfirm={() => onDelete(userData)}
              label={
                "Are you sure you want to delete this? This action cannot be undone."
              }
              isLoading={deleteLoading}
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "column",
            mb: 5,
          }}
        >
          <Grid container>
            <Grid item xs={4}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 18,
                  mt: 1,
                  color: "#131313B3",
                }}
              >
                Extension No:
                <Typography
                  sx={{
                    ml: 1,
                    fontWeight: "medium",
                    fontSize: 18,
                    color: "#131313B3",
                  }}
                >
                  {userData.extension}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Tooltip title={userData?.departments?.name} arrow>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: 18,
                    mt: 1,
                    color: "#131313B3",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  Department:
                  <Typography
                    sx={{
                      ml: 1,
                      fontWeight: "medium",
                      fontSize: 18,
                      color: "#131313B3",
                    }}
                  >
                    {userData?.departments?.name}
                  </Typography>
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4}>
              <Tooltip title={userData.phone} arrow>
                <Typography
                  sx={{
                    color: "#23239C",
                    fontSize: 16,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  <PhoneInTalkOutlinedIcon sx={{ color: "#23239CAB", mr: 1 }} />
                  {userData.phone}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={5} sx={{ display: "flex", flexWrap: "wrap" }}>
              <Tooltip title={userData.email} arrow>
                <Typography
                  sx={{
                    color: "#23239C",
                    fontSize: 16,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  <EmailOutlinedIcon sx={{ color: "#23239CAB", mr: 1 }} />
                  {userData.email}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={3} display={"flex"} flexDirection={"row-reverse"}>
              <Tooltip title={"Change Password"}>
                <Button
                  variant="text"
                  sx={{
                    padding: 0,
                    alignItems: "right",
                  }}
                  onClick={handleChangePasswordOpen}
                >
                  <LockOpenIcon sx={{ color: "#23239CAB", fontSize: 18 }} />
                  <Typography
                    sx={{
                      textTransform: "none",
                      color: "#23239C",
                      fontSize: 15,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Change Password
                  </Typography>
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          {openChangePassword && (
            <ChangePasswordModalComponent
              open={openChangePassword}
              handleClose={handleChangePasswordClose}
              handleOpen={handleChangePasswordOpen}
              userId={userData._id}
              snackbarData={snackbarData}
              handleSnackbarClose={handleSnackbarClose}
              setSnackbarData={setSnackbarData}
            />
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography>Queues</Typography>
            {userData.queues && userData.queues.length > 0 ? (
              expanded ? (
                userData.queues.map((queueItem, index) => (
                  <Chip
                    key={index}
                    label={queueItem.name}
                    variant="contained"
                    sx={{
                      my: 1,
                      mr: 1,
                      backgroundColor: "rgba(35, 35, 156, 0.7)",
                      color: "white",
                    }}
                  />
                ))
              ) : (
                userData.queues.slice(0, 4).map((queueItem, index) => (
                  <Chip
                    key={index}
                    label={queueItem.name}
                    variant="contained"
                    sx={{
                      my: 1,
                      mr: 1,
                      backgroundColor: "rgba(35, 35, 156, 0.7)",
                      color: "white",
                    }}
                  />
                ))
              )
            ) : (
              <Typography>No queues found</Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography>{tabValue === 1 ? "Supervisor" : "Agent"}</Typography>
            {userData.role === "agent" ? (
              userData.supervisors && userData.supervisors.length > 0 ? (
                expanded ? (
                  userData.supervisors.map((supervisorItem, index) => (
                    <Chip
                      key={index}
                      label={`${supervisorItem.firstName} ${supervisorItem.lastName}`}
                      variant="outlined"
                      sx={{
                        my: 1,
                        mr: 1,
                        borderColor: "rgba(35, 35, 156, 0.7)",
                        color: "rgba(19, 19, 19, 0.7)",
                        fontWeight: "medium",
                      }}
                    />
                  ))
                ) : (
                  userData.supervisors
                    .slice(0, 4)
                    .map((supervisorItem, index) => (
                      <Chip
                        key={index}
                        label={`${supervisorItem.firstName} ${supervisorItem.lastName}`}
                        variant="outlined"
                        sx={{
                          my: 1,
                          mr: 1,
                          borderColor: "rgba(35, 35, 156, 0.7)",
                          color: "rgba(19, 19, 19, 0.7)",
                          fontWeight: "medium",
                        }}
                      />
                    ))
                )
              ) : (
                <Typography>No supervisors found</Typography>
              )
            ) : userData.agents && userData.agents.length > 0 ? (
              expanded ? (
                userData.agents.map((agentItem, index) => (
                  <Chip
                    key={index}
                    label={`${agentItem.firstName} ${agentItem.lastName}`}
                    variant="outlined"
                    sx={{
                      my: 1,
                      mr: 1,
                      borderColor: "rgba(35, 35, 156, 0.7)",
                      color: "rgba(19, 19, 19, 0.7)",
                      fontWeight: "medium",
                    }}
                  />
                ))
              ) : (
                userData.agents.slice(0, 4).map((agentItem, index) => (
                  <Chip
                    key={index}
                    label={`${agentItem.firstName} ${agentItem.lastName}`}
                    variant="outlined"
                    sx={{
                      my: 1,
                      mr: 1,
                      borderColor: "rgba(35, 35, 156, 0.7)",
                      color: "rgba(19, 19, 19, 0.7)",
                      fontWeight: "medium",
                    }}
                  />
                ))
              )
            ) : (
              <Typography>No agents found</Typography>
            )}
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Typography
            sx={{
              display: "flex",
              color: "#131313B3",
              fontSize: 15,
              alignItems: "baseline",
            }}
          >
            Created At:
            <Typography sx={{ color: "#131313B3", fontSize: 15, ml: 1 }}>
              {dayjs(userData.createdAt).format("YYYY-MM-DD HH:mm:ss")}
            </Typography>
          </Typography>
          {expanded ? (
            <Button onClick={() => setExpanded(false)}>
              <Typography
                sx={{ textTransform: "none", color: "#23239C", fontSize: 15 }}
              >
                View Less
              </Typography>
              <KeyboardArrowUpIcon sx={{ color: "#23239CAB" }} />
            </Button>
          ) : (
            <Button onClick={() => setExpanded(true)}>
              <Typography
                sx={{ textTransform: "none", color: "#23239C", fontSize: 15 }}
              >
                View More
              </Typography>
              <KeyboardArrowDownIcon sx={{ color: "#23239CAB" }} />
            </Button>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default UserInfoComponent;
