import { TextField } from "@mui/material";

const TextFormField = ({ label, name, value, onChange, type, required }) => {
  return (
    <TextField
      label={label}
      variant="outlined"
      name={name}
      value={value}
      onChange={onChange}
      fullWidth
      type={type}
      required={required}
      sx={{
        mb: 2,
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#23239C",
          },
          "&:hover fieldset": {
            borderColor: "#131313", // Border color on hover
          },
          "&.Mui-focused fieldset": {
            borderColor: "#23239C", // Border color when focused
          },
        },
        "& .MuiFormLabel-root": {
          color: "#23239C",
        },
      }}
    />
  );
};

export default TextFormField;
