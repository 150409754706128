import React, { useEffect, useState } from "react";
import { Box, IconButton, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { instance } from "app/utils/axiosRequest";
import {
  adminRootCauseandResolution,
  allcategoryList,
} from "app/utils/api-urls/AdminAPiUrls";
import GetRootCauseandResolution from "./GetRootCauseandResolution";
import AddRootCause from "./AddRootCause";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import qs from "qs";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";

const RootCauseandResolution = () => {
  const [value, setValue] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [rootCauses, setRootCauses] = useState([]);

  const {
    data: issueCategory,
    isloading: isLoading,
    refetch: refetch,
  } = useQuery(
    ["admin-report-issue-categories"],
    () => instance.get(allcategoryList.category_list),
    {
      enabled: true,
      onSuccess: (data) => {
        setCategoryData(data?.data);
      },
    }
  );

  const {
    data: rootCauseData,
    isLoading: rootCauseIsLoading,
    refetch: rootcauserefetch,
  } = useQuery(
    ["admin-root-cause", categoryData[value]?._id],
    () => {
      const issueId = categoryData[value]?._id;
      if (!issueId) {
        return null;
      }

      const queryString = qs.stringify({ issue: issueId });
      const url = `${adminRootCauseandResolution.admin_root_caue_and_resolution}?${queryString}`;
      return instance.get(url);
    },
    {
      enabled: !!categoryData[value]?._id,
      onSuccess: (data) => {
        setRootCauses(data?.data);
      },
    }
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenAddModal = () => {
    setEditData(null);
    setIsModalOpen(true);
  };

  const handleOpenEditModal = (data) => {
    setEditData(data);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      {" "}
      <FullpageLoader open={isLoading || rootCauseIsLoading} />
      <AddRootCause
        refetch={rootcauserefetch}
        open={isModalOpen}
        handleClose={handleCloseModal}
        editData={editData}
      />{" "}
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Typography> Add Root Cause</Typography>
          <Tooltip title="Add Root Cause">
            <IconButton onClick={handleOpenAddModal}>
              <AddCircleOutlineOutlined
                sx={{ fontSize: "35px", color: "#1F4172" }}
              />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="issue category tabs"
          >
            {categoryData?.map((category, index) => (
              <Tab key={category._id} label={category.name} />
            ))}
          </Tabs>
        </Box>
        <GetRootCauseandResolution
          rootCauses={rootCauses || []}
          refetch={rootcauserefetch}
          openEditModal={handleOpenEditModal}
        />
      </Box>
    </>
  );
};

export default RootCauseandResolution;
