import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import { Box, Button, Card, Typography, alpha } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import SelectComponent from "app/components/Shared/Select/Select.component";
import { adminCallBlasting } from "app/utils/api-urls/AdminAPiUrls";
import { instance } from "app/utils/axiosRequest";
import { Suspense, lazy, useRef, useState } from "react";

const CallBlastPreviewModal = lazy(() => import("./CallBlastPreviewModal"));
const SnackbarComponent = lazy(() => import("app/components/SnackBar"));

const FileManagement = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [showStartBlasting, setShowStartBlasting] = useState(false);
  const [data, setData] = useState({
    id: "",
    file: selectedFile || null,
  });

  const [snackbarData, setSnackbarData] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const {
    data: campaignData,
    isLoading,
    isRefetching,
  } = useQuery(["campaign-data"], () =>
    instance.get(adminCallBlasting.admin_call_blasting_campaign_api)
  );

  const campaignNames = campaignData?.data?.data.map((item) => ({
    id: item._id,
    label: item.campaignName,
    value: item._id,
    audio: item.audio,
  }));

  const onSubmit = (e) => {
    e.preventDefault();
    setData({
      ...data,
      file: selectedFile,
    });
    setShowStartBlasting(true);
  };

  const handleReset = () => {
    setSelectedFile(null);
    setData({
      id: "",
      file: null,
    });
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return; // No file selected, do nothing
    }

    const isValidFileType = validateFileType(file);

    if (!isValidFileType) {
      event.target.value = null; // Clear the input field
      setSnackbarData({
        open: true,
        severity: "error",
        message: "Invalid file type. Please upload a CSV, XLSX, or XLS file.",
      });
      return;
    }

    setSelectedFile(file);
    setData({
      ...data,
      file: file,
    });
  };

  const validateFileType = (file) => {
    // Allowed file types (extensions)
    const allowedTypes = [".csv", ".xls", ".xlsx"];

    // Check if the file extension is in the allowedTypes array
    const extension = file.name.split(".").pop().toLowerCase();
    return allowedTypes.includes("." + extension);
  };

  const handleSelectChange = (event) => {
    const selectedCampaignId = event.target.value;
    setData({
      ...data,
      id: selectedCampaignId,
    });
  };

  const handleClose = () => {
    setShowStartBlasting(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarData({ ...snackbarData, open: false });
  };

  const handleSetSnackbarData = (newSnackbarData) => {
    setSnackbarData(newSnackbarData);
  };

  return (
    <>
      <SnackbarComponent
        open={snackbarData.open}
        handleClose={handleSnackbarClose}
        data={snackbarData}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          marginBottom: 2,
          mt: 1,
        }}
      >
        <FullpageLoader open={isLoading || isRefetching} />
        <Typography variant="h6" sx={{ mb: 2, ml: 2 }}>
          Call Blasting
        </Typography>
        <Box sx={{ mx: 2, backgroundColor: alpha("#23239C", 0.05) }}>
          <SelectComponent
            options={campaignNames}
            label={"Select Campaign"}
            handleChange={handleSelectChange}
            value={data?.id}
            name={"id"}
            required={false}
          />
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          variant="outlined"
          sx={{
            m: 2,
            border: "1px solid rgba(35, 35, 156, 0.1)",
            backgroundColor: alpha("#23239C", 0.05),
            borderRadius: 1,
            p: 1,
            alignItems: "center",
          }}
        >
          <Typography variant="body1" fontWeight={400} sx={{ pl: 1 }}>
            Contact Numbers
          </Typography>
          {selectedFile && (
            <Typography color="text.secondary" variant="body3">
              {selectedFile?.name}
            </Typography>
          )}
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            onChange={handleFileChange}
            ref={fileInputRef}
            accept=".csv,.xls,.xlsx"
          />
          <Button
            size="small"
            variant="outlined"
            onClick={handleButtonClick}
            component="label"
            sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "right",
              color: "#23239C",
              boxShadow: "0 0 0 0.2px rgba(35, 35, 156, 0.7)",
              "&:hover": {
                boxShadow: "0 0 0 0.2px rgba(35, 35, 156, 0.9)",
              },
            }}
          >
            <AccountBoxOutlinedIcon sx={{ mr: 0.5 }} />
            Upload
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "40vh",
            justifyContent: "center",
          }}
        >
          <Card
            variant="outlined"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(35, 35, 156, 0.05)",
              height: "20vh",
              width: 300,
              mt: 2,
            }}
          >
            <Typography
              variant="body1"
              fontWeight={400}
              sx={{
                alignSelf: "center",
              }}
            >
              Preview
            </Typography>
          </Card>
        </Box>
        <Button
          variant="contained"
          onClick={onSubmit}
          sx={{
            width: 150,
            justifySelf: "end",
            alignSelf: "end",
            mr: 2,
            backgroundColor: "#23239C",
            "&:hover": {
              backgroundColor: alpha("#23239C", 0.85),
            },
          }}
        >
          Start Blasting
        </Button>
        <Suspense fallback={<FullpageLoader />}>
          {showStartBlasting && (
            <CallBlastPreviewModal
              data={data}
              campaignNames={campaignNames}
              handleSetSnackbarData={handleSetSnackbarData}
              handleClose={handleClose}
              showStartBlasting={showStartBlasting}
              handleReset={handleReset}
              selectedFile={selectedFile}
            />
          )}
        </Suspense>
      </Box>
    </>
  );
};

export default FileManagement;
