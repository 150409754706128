import { Card, CardContent, Grid, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import AudioManagement from "./Call-Blasting-Tabs/AudioManagement";
import CampaignManagement from "./Call-Blasting-Tabs/CampaignManagement";
import FileManagement from "./Call-Blasting-Tabs/FileManagement";
import ReportPage from "./Call-Blasting-Tabs/ReportPage";

const TabPage = ({ tabs }) => {
  const hash = window.location.hash.substring(1);
  const initialTabIndex = tabs.findIndex(
    (tab) => tab.label.toLowerCase().replace(/\s+/g, "-") === hash
  );
  const [activeTab, setActiveTab] = useState(
    initialTabIndex !== -1 ? initialTabIndex : 0
  );

  const handleHashChange = () => {
    const newHash = window.location.hash.substring(1);
    const newTabIndex = tabs.findIndex(
      (tab) => tab.label.toLowerCase().replace(/\s+/g, "-") === newHash
    );
    if (newTabIndex !== -1) {
      setActiveTab(newTabIndex);
    }
  };

  window.onhashchange = handleHashChange;

  const handleTabChange = (_, newTab) => {
    setActiveTab(newTab);
    const selectedTabName = tabs[newTab].label;
    window.location.hash = selectedTabName.toLowerCase().replace(/\s+/g, "-");
  };

  return (
    <Card
      sx={{
        height: "100vh",
        width: "100%",
        // boxShadow: "4px 2px 24px rgba(35, 35, 156, 0.2)",
        borderRadius: "14px",
      }}
    >
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Tabs value={activeTab} onChange={handleTabChange}>
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  style={{ flexGrow: 1, flexBasis: 0 }}
                />
              ))}
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            {tabs[activeTab].component}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const CallBlastingTabs = () => {
  const tabs = [
    { label: "Audio Management", component: <AudioManagement /> },
    { label: "Campaign Management", component: <CampaignManagement /> },
    { label: "File Management", component: <FileManagement /> },
    { label: "Report Page", component: <ReportPage /> },
  ];

  return <TabPage tabs={tabs} />;
};

export default CallBlastingTabs;
