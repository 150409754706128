import React from "react";
import { Autocomplete, Box, Checkbox, Chip, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CustomScrollBarBox from "../CustomScrollBarBox/CustomScrollBarBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AutocompleteComponent = ({
  options,
  onSelect,
  fieldName,
  label,
  value,
}) => {
  const filteredOptions = options.filter((option) => value.includes(option.id));
  const [values, setValues] = React.useState(filteredOptions);

  const handleAutocompleteChange = (event, value) => {
    setValues(value);
    if (onSelect) {
      const selectedIds = value.map((item) => item.id);
      onSelect(fieldName, selectedIds);
    }
  };

  return (
    <Autocomplete
      multiple
      options={options}
      onChange={handleAutocompleteChange}
      value={values}
      getOptionLabel={(option) => option.name}
      MenuProps={MenuProps}
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            checked={values.some((v) => v.id === option.id)}
          />
          {option.name || option?.name}
        </li>
      )}
      renderTags={(value, getTagProps) => (
        <CustomScrollBarBox height={100} scrollbarWidth={"4px"} mb={0}>
          <Box>
            {value.map((option, index) => (
              <Chip
                key={index}
                label={option?.name}
                {...getTagProps({ index })}
              />
            ))}
          </Box>
        </CustomScrollBarBox>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={label}
          sx={{
            mb: 2,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#23239C",
              },
              "&:hover fieldset": {
                borderColor: "#131313", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "#23239C", // Border color when focused
              },
            },
            "& .MuiFormLabel-root": {
              color: "#23239C",
            },
          }}
        />
      )}
    />
  );
};

export default AutocompleteComponent;
