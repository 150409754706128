import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useMutation } from "@tanstack/react-query";
import SnackbarComponent from "app/components/SnackBar";
import { adminUserSettings } from "app/utils/api-urls/AdminAPiUrls";
import { instance } from "app/utils/axiosRequest";
import { useState } from "react";

const ChangePasswordModalComponent = ({
  open,
  handleClose,
  handleOpen,
  userId,
  snackbarData,
  handleSnackbarClose,
  setSnackbarData,
}) => {
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [passwordVisibility, setPasswordVisibility] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });

  const [touchedFields, setTouchedFields] = useState({
    password: false,
    confirmPassword: false,
  });

  const editUserPassword = useMutation(
    ["edit-user"],
    ({ userId, passwordData }) =>
      instance.patch(
        adminUserSettings.admin_user_changepassword + `/${userId}`,
        passwordData
      ),
    {
      onSuccess: () => {
        setSnackbarData({
          open: true,
          severity: "success",
          message: "Password Changed!",
        });
        handleModalClose();
      },
      onError: () => {
        setSnackbarData({
          open: true,
          severity: "error",
          message: "Error Changing Password",
        });
      },
    }
  );

  const handleEditUserPassword = (userId, passwordData) => {
    editUserPassword.mutate({ userId, passwordData });
  };

  // Toggle password visibility in the UI.
  const handleClickShowPassword = (field) => {
    setPasswordVisibility({
      ...passwordVisibility,
      [field]: !passwordVisibility[field],
    });
  };

  const resetForm = () => {
    setFormData({
      password: "",
      confirmPassword: "",
    });
  };

  const handleModalClose = () => {
    handleClose();
    resetForm();
  };

  const handleSave = () => {
    const passwordError = validateField("password");
    const confirmPasswordError = validateField("confirmPassword");

    if (!passwordError && !confirmPasswordError) {
      handleEditUserPassword(userId, formData);
    }
  };

  // Mark a field as touched (focused) for validation.
  const handleFieldFocus = (fieldName) => {
    setTouchedFields({
      ...touchedFields,
      [fieldName]: true,
    });
  };
  // Validate a field based on specific criteria and return an error message if validation fails.
  const validateField = (fieldName) => {
    const value = formData[fieldName];
    const hasValue = value.trim() !== "";
    const hasValidLength = value.length >= 8;
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSpecialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
      value
    );

    if (!touchedFields[fieldName] || !hasValue) return "";
    if (!touchedFields[fieldName] && !hasValue) return "Password is required";
    if (!hasValidLength) return "Password should be at least 8 characters long";
    if (!hasUpperCase)
      return "Password should contain at least one uppercase letter";
    if (!hasLowerCase)
      return "Password should contain at least one lowercase letter";
    if (!hasNumber) return "Password should contain at least one number";
    if (!hasSpecialCharacter)
      return "Password should contain at least one special character";
    if (fieldName === "confirmPassword" && value !== formData.password)
      return "Passwords do not match";
    return "";
  };

  return (
    <>
      <SnackbarComponent
        open={snackbarData.open}
        handleClose={handleSnackbarClose}
        data={snackbarData}
      />
      <Modal open={open} onClose={handleClose}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            width: "25rem",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: "1 4px 6px rgba(35, 35, 156, 0.2)",
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 2,
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            mb={2}
          >
            <Typography variant="h6" alignItems="baseline" ml={1}>
              Change Password
            </Typography>
            <IconButton onClick={handleModalClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Password"
                fullWidth
                variant="outlined"
                name="password"
                type={passwordVisibility.showPassword ? "text" : "password"}
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                onFocus={() => handleFieldFocus("password")}
                helperText={
                  <Typography sx={{ color: "red", fontSize: "14px" }}>
                    {validateField("password")}
                  </Typography>
                }
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      onClick={() => handleClickShowPassword("showPassword")}
                      position="end"
                    >
                      {passwordVisibility.showPassword ? (
                        <VisibilityOutlinedIcon
                          sx={{ cursor: "pointer", color: "#23239C" }}
                        />
                      ) : (
                        <VisibilityOffOutlinedIcon
                          sx={{ cursor: "pointer", color: "#23239C" }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#23239C",
                    "&:focus-within": {
                      borderColor: "#23239C",
                      color: "#23239C",
                    },
                    "&:hover": {
                      borderColor: "grey",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#23239C",
                      color: "#23239C",
                    },
                  },
                  "& .MuiFormLabel-root": {
                    color: "#23239C",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Confirm Password"
                fullWidth
                variant="outlined"
                name="confirmPassword"
                type={
                  passwordVisibility.showConfirmPassword ? "text" : "password"
                }
                value={formData.confirmPassword}
                onChange={(e) =>
                  setFormData({ ...formData, confirmPassword: e.target.value })
                }
                onFocus={() => handleFieldFocus("confirmPassword")}
                helperText={
                  <Typography sx={{ color: "red", fontSize: "14px" }}>
                    {validateField("confirmPassword")}
                  </Typography>
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      onClick={() =>
                        handleClickShowPassword("showConfirmPassword")
                      }
                      position="end"
                    >
                      {passwordVisibility.showConfirmPassword ? (
                        <VisibilityOutlinedIcon
                          sx={{ cursor: "pointer", color: "#23239C" }}
                        />
                      ) : (
                        <VisibilityOffOutlinedIcon
                          sx={{ cursor: "pointer", color: "#23239C" }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#23239C",
                    "&:focus-within": {
                      borderColor: "#23239C",
                      color: "#23239C",
                    },
                    "&:hover": {
                      borderColor: "grey",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#23239C",
                      color: "#23239C",
                    },
                  },
                  "& .MuiFormLabel-root": {
                    color: "#23239C",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={editUserPassword.isLoading}
            sx={{
              display: "flex",
              width: 80,
              justifyItems: "end",
              alignSelf: "flex-end",
              backgroundColor: "#23239C",
              "&:hover": {
                backgroundColor: alpha("#23239C", 0.85),
              },
            }}
          >
            {editUserPassword.isLoading ? (
              <CircularProgress size={18} color="inherit" />
            ) : (
              "Save"
            )}
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default ChangePasswordModalComponent;
