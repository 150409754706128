import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { CloseOutlined, Add, Remove } from "@mui/icons-material";
import TextFormField from "../UserSetting/UserPageComponents/TextFieldComponent";
import MultiSelectComponent from "app/components/Shared/MultiSelectComponent/MultiSelectComponent";
import { instance } from "app/utils/axiosRequest";
import {
  adminRootCauseandResolution,
  allcategoryList,
} from "app/utils/api-urls/AdminAPiUrls";
import { useMutation, useQuery } from "@tanstack/react-query";
import SnackbarComponent from "app/components/SnackBar";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";

const AddRootCause = ({ open, handleClose, refetch, editData }) => {
  const style = {
    width: "30rem",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "1 4px 6px rgba(35, 35, 156, 0.2)",
    bgcolor: "background.paper",
    p: 4,
    borderRadius: 2,
  };
  const initialFormData = {
    rootCauseName: "",
    resolutions: [""],
    issue: [],
  };
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [issuesOptions, setissuesOptions] = useState([]);

  const [snackbarData, setSnackbarData] = useState({
    show: false,
    message: "",
    severity: "",
  });

  const {
    data: issueCategory,
    isLoading,
    isRefetching,
  } = useQuery(
    ["admin-report-issue-categories"],
    () => instance.get(allcategoryList.category_list),
    {
      onSuccess: (data) => {
        setissuesOptions(
          data?.data.map((name) => ({
            id: name._id,
            label: name.name,
          }))
        );
      },
    }
  );

  const { mutate: addRootCause, isLoading: isAdding } = useMutation({
    mutationFn: (userData) =>
      instance.post(
        adminRootCauseandResolution.admin_root_caue_and_resolution,
        userData
      ),
    onSuccess: () => {
      refetch();
      setSnackbarData({
        show: true,
        message: "Added root cause successfully!",
        severity: "success",
      });
      refetch();
      handleClose();
    },
    onError: (error) => {
      setSnackbarData({
        show: true,
        message: "Failed to add root cause.",
        severity: "error",
      });
    },
  });

  const { mutate: updateRootCause, isLoading: updateRootCauseLoading } =
    useMutation({
      mutationFn: (data) => {
        const url = `${adminRootCauseandResolution.admin_root_caue_and_resolution}/${data?.id}`;
        return instance.patch(url, data?.userData);
      },
      onSuccess: () => {
        setSnackbarData({
          show: true,
          message: "Root cause updated successfully!",
          severity: "success",
        });
        refetch();
        handleClose();
      },
      onError: (error) => {
        setSnackbarData({
          show: true,
          message: `Failed to update root cause. Error: ${
            error.message || "Unknown error"
          }`,
          severity: "error",
        });
      },
    });

  useEffect(() => {
    if (editData && Object.keys(editData).length !== 0) {
      setIsEdit(true);
      setFormData({
        rootCauseName: editData.name || "",
        resolutions: editData.resolutions
          ? editData.resolutions.map((resolution) => ({
              _id: resolution._id, // Include the _id if it exists
              name: resolution.name, // Always include the name
            }))
          : [""],
        issue: editData.issues ? editData.issues.map((issue) => issue._id) : [],
      });
    } else {
      setIsEdit(false);
      setFormData(initialFormData);
    }
  }, [editData]);

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    if (name === "rootCauseName") {
      setFormData((prev) => ({ ...prev, [name]: value }));
    } else if (name.startsWith("resolutions")) {
      const newResolutions = [...formData.resolutions];
      const updatedResolution = { ...newResolutions[index], name: value };
      newResolutions[index] = updatedResolution;
      setFormData((prev) => ({ ...prev, resolutions: newResolutions }));
    }
  };

  const handleIssueChange = (value) => {
    setFormData((prev) => ({ ...prev, issue: value }));
  };

  const addResolution = () => {
    setFormData((prev) => ({
      ...prev,
      resolutions: [...prev.resolutions, ""],
    }));
  };

  const removeResolution = (index) => {
    const newResolutions = [...formData.resolutions];
    newResolutions.splice(index, 1);
    setFormData((prev) => ({ ...prev, resolutions: newResolutions }));
  };

  const handleSnackbarClose = () => {
    setSnackbarData((prev) => ({ ...prev, show: false }));
  };

  const handleSubmit = () => {
    const payload = {
      name: formData.rootCauseName,
      resolutions: formData.resolutions.map((resolution) => {
        if (resolution._id) {
          // Existing resolution, send both _id and the updated name
          return { _id: resolution._id, name: resolution.name };
        }
        // New resolution, send name only
        return { name: resolution.name };
      }),
      issues: formData.issue,
    };

    if (isEdit) {
      updateRootCause({ id: editData._id, userData: payload });
    } else {
      addRootCause(payload);
    }
  };

  return (
    <>
      <SnackbarComponent
        open={snackbarData.show}
        handleClose={handleSnackbarClose}
        data={snackbarData}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            mb={2}
          >
            <Typography variant="h6" align="left" gutterBottom ml={1}>
              {isEdit ? "Edit Root Cause" : "Add New Root Cause"}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFormField
                label="Root Cause Name"
                name="rootCauseName"
                value={formData.rootCauseName}
                onChange={(e) => handleChange(e)}
                type="text"
                required={true}
              />
            </Grid>

            {formData?.resolutions?.map((resolution, index) => (
              <React.Fragment key={index}>
                <Grid item xs={10}>
                  <TextFormField
                    label={`Resolution ${index + 1}`}
                    name={`resolutions_${index}`}
                    value={resolution.name || ""}
                    onChange={(e) => handleChange(e, index)}
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  {index === 0 ? (
                    <IconButton onClick={addResolution}>
                      <Add fontSize="10px" />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => removeResolution(index)}>
                      <Remove fontSize="10px" />
                    </IconButton>
                  )}
                </Grid>
              </React.Fragment>
            ))}

            <Grid item xs={12}>
              <MultiSelectComponent
                label="Issue Category"
                name="Issue"
                value={formData.issue}
                initialOptions={issuesOptions}
                required={true}
                handleChange={handleIssueChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                // disabled={isSubmitDisabled() || isAdding}
                onClick={handleSubmit}
                sx={{ mt: 2, float: "right" }}
              >
                {isEdit ? "Update" : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default AddRootCause;
