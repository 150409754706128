import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import { Box, Tooltip, Typography, alpha } from "@mui/material";

const UserListComponent = ({ userData, onClick }) => {
  const handleClick = () => {
    onClick(userData);
  };
  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 3,
        boxShadow: `2px 2px 24px ${alpha("#23239C", 0.05)}`,
        transition: "background-color 0.3s ease, transform 0.1s ease",
        "&:hover": {
          backgroundColor: alpha("#23239C", 0.15),
          cursor: "pointer",
        },
        "&:active": {
          backgroundColor: alpha("#23239C", 0.15),
          transform: "scale(0.95)",
        },
        "&:focus": {
          backgroundColor: alpha("#23239C", 0.15),
        },
      }}
      onClick={handleClick}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
          mb: 2,
        }}
      >
        <Typography
          sx={{
            fontWeight: "medium",
            fontSize: 22,
            color: "#131313",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {userData?.firstName} {userData?.middleName} {userData?.lastName}
        </Typography>
        <Typography
          sx={{ fontSize: 16, color: "#131313B3", fontWeight: "medium" }}
        >
          Extension No: {userData?.extension}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Tooltip title={userData?.phone} arrow>
          <Typography sx={{ color: "#23239C" }}>
            <PhoneInTalkOutlinedIcon sx={{ color: "#23239CAB", mr: 1 }} />
            {userData?.phone}
          </Typography>
        </Tooltip>
        <Tooltip title={userData?.email} arrow>
          <Typography sx={{ color: "#23239C" }}>
            <EmailOutlinedIcon sx={{ color: "#23239CAB", mr: 1 }} />
            {userData?.email}
          </Typography>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default UserListComponent;
