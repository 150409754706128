import { Box, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import GetStatusTypes from "./GetStatusType";

const StatusTypes = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="email tabs">
          <Tab label="Status Types" />
        </Tabs>
      </Box>
      <Typography>
        {value === 0 && (
          <Box p={3}>
            <GetStatusTypes />
          </Box>
        )}
      </Typography>
    </Box>
  );
};

export default StatusTypes;
