import {
  Box,
  Button,
  FormControl,
  Modal,
  Typography,
  alpha,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import SnackbarComponent from "app/components/SnackBar";
import { adminTicketTypes } from "app/utils/api-urls/AdminAPiUrls";
import { instance } from "app/utils/axiosRequest";
import { useEffect, useState } from "react";
import TextFormField from "../UserSetting/UserPageComponents/TextFieldComponent";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
const AddTicketTypeModal = ({
  open,
  handleClose,
  refetch,
  mode,
  data,
  openSnackbar,
  handleSnackbarClose,
  snackbarData,
  setSnackbarData,
  dataId,
}) => {
  // The state `ticketName` is set to `data` if it exists,
  // or to an object with an empty name `{ name: "" }` otherwise.
  const [ticketName, setTicketName] = useState({ name: "" });

  useEffect(() => {
    if (mode === "edit" && data) {
      setTicketName({ name: data?.name });
    } else {
      setTicketName({ name: "" });
    }
  }, [mode, data]);

  const addTicketTypes = useMutation(
    ["post-new-ticket-type"],
    (newTicket) =>
      instance.post(adminTicketTypes.admin_ticket_type_api, newTicket),
    {
      onSuccess: (response) => {
        setSnackbarData({
          open: true,
          severity: "success",
          message: "New Ticket-Type Created successfully!",
        });
        handleClose();
        resetForm();
        refetch();
      },
      onError: () => {
        setSnackbarData({
          open: true,
          severity: "error",
          message: "Error Creating Ticket-Type!",
        });
      },
    }
  );

  const editTicketTypes = useMutation(
    ["edit-ticket-type"],
    (ticketData) =>
      instance.patch(
        adminTicketTypes.admin_ticket_type_api + `/${ticketData._id}`,
        ticketData
      ),
    {
      onSuccess: () => {
        handleClose();
        resetForm();
        refetch();
        setSnackbarData({
          open: true,
          severity: "success",
          message: "Updated successfully",
        });
      },
      onError: () => {
        setSnackbarData({
          open: true,
          severity: "error",
          message: "Error Updating Ticket Name",
        });
      },
    }
  );

  const resetForm = () => {
    setTicketName({ name: "" });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTicketName((prevTicketName) => ({ ...prevTicketName, [name]: value }));
  };

  const handleSubmit = () => {
    if (mode === "edit") {
      editTicketTypes.mutate({ _id: dataId, name: ticketName?.name });
    } else {
      addTicketTypes.mutate({name: ticketName?.name});
    }
  };

  return (
    <>
      <SnackbarComponent
        open={openSnackbar}
        handleClose={handleSnackbarClose}
        data={snackbarData}
      />
      {(addTicketTypes.isLoading || editTicketTypes.isLoading) && (
        <FullpageLoader
          open={addTicketTypes.isLoading || editTicketTypes.isLoading}
        />
      )}
      <Modal
        open={open && !(addTicketTypes.isLoading || editTicketTypes.isLoading)}
        onClose={handleClose}
      >
        <Box
          sx={{
            width: "22rem",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: "1 4px 6px rgba(35, 35, 156, 0.2)",
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 2,
          }}
        >
          <FormControl fullWidth>
            <Typography variant="h6">
              {mode === "edit" && data ? "Edit" : "Add New"} Ticket-Type
            </Typography>
            <Box marginTop="20px" display="flex" flexDirection="row" gap="1rem">
              <TextFormField
                label="Ticket Type Name"
                value={ticketName?.name || ""}
                onChange={handleChange}
                type={"text"}
                required={true}
                name={"name"}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" marginTop="20px">
              <Button
                onClick={handleSubmit}
                variant="contained"
                sx={{
                  mx: 1,
                  backgroundColor: "#23239C",
                  "&:hover": {
                    backgroundColor: alpha("#23239C", 0.85),
                  },
                }}
              >
                Submit
              </Button>
            </Box>
          </FormControl>
        </Box>
      </Modal>
    </>
  );
};

export default AddTicketTypeModal;
