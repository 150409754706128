import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";

const CustomDataGrid = ({ height, rows, columns, getRowId, onRowClick }) => {
  return (
    <Box height={height} sx={{ border: "1px solid #d3d3d3", borderRadius: 1 }}>
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={getRowId}
        hideFooter
        height={height}
        disableColumnMenu
        onRowClick={onRowClick}
        sx={{
          "& ::-webkit-scrollbar": {
            width: "6px",
          },
          "& ::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "& ::-webkit-scrollbar-thumb": {
            background: "#CCCCCC",
            borderRadius: "5px",
          },
          "& ::-webkit-scrollbar-thumb:hover": {
            background: "#C1C1C1",
          },
        }}
      />
    </Box>
  );
};

export default CustomDataGrid;
