import {
  Box,
  Button,
  FormControl,
  Modal,
  Typography,
  alpha,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import SnackbarComponent from "app/components/SnackBar";
import { adminStatusTypes } from "app/utils/api-urls/AdminAPiUrls";
import { instance } from "app/utils/axiosRequest";
import { useEffect, useState } from "react";
import TextFormField from "../UserSetting/UserPageComponents/TextFieldComponent";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";

const AddStatusModal = ({
  open,
  handleClose,
  refetch,
  mode,
  data,
  openSnackbar,
  handleSnackbarClose,
  snackbarData,
  setSnackbarData,
  dataId,
}) => {
  // The state `statusName` is set to `data` if it exists, 
  // or to an object with an empty name `{ name: "" }` otherwise.
  // const initialStatusData = data || { name: "" };
  // const [statusName, setStatusName] = useState(initialStatusData);
  const [statusName, setStatusName] = useState({ name: "" });

  useEffect(() => {
    setStatusName(mode === "edit" && data ? data : { name: "" });
  }, [mode, data]);
  
  

  const addStatusTypes = useMutation(
    ["post-new-status"],
    (newStatus) =>
      instance.post(adminStatusTypes.admin_status_types, newStatus),
    {
      onSuccess: (response) => {
        setSnackbarData({
          open: true,
          severity: "success",
          message: "Status type created successfully!",
        });
        handleClose();
        resetForm();
        refetch();
      },
      onError: () => {
        setSnackbarData({
          open: true,
          severity: "error",
          message: "Error Creating Status-Type!",
        });
      },
    }
  );

  const editStatusTypes = useMutation(
    ["edit-status"],
    (statusData) =>
      instance.patch(
        adminStatusTypes.admin_status_types + `/${statusData._id}`,
        statusData
      ),
    {
      onSuccess: () => {
        handleClose();
        resetForm();
        refetch();
        setSnackbarData({
          open: true,
          severity: "success",
          message: "Updated successfully",
        });
      },
      onError: () => {
        setSnackbarData({
          open: true,
          severity: "error",
          message: "Error Updating Status Name",
        });
      },
    }
  );

  const resetForm = () => {
    setStatusName({ name: "" });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setStatusName((prevStatusName) => ({ ...prevStatusName, [name]: value }));
  };

  const handleSubmit = () => {
    if (mode === "edit") {
      editStatusTypes.mutate({ _id: dataId, name: statusName?.name });
    } else {
      addStatusTypes.mutate({name: statusName?.name});
    }
  };

  return (
    <>
      <SnackbarComponent
        open={openSnackbar}
        handleClose={handleSnackbarClose}
        data={snackbarData}
      />
      {(addStatusTypes.isLoading || editStatusTypes.isLoading) && (
        <FullpageLoader
          open={addStatusTypes.isLoading || editStatusTypes.isLoading}
        />
      )}
      <Modal open={open && !(addStatusTypes.isLoading || editStatusTypes.isLoading)} onClose={handleClose}>
        <Box
          sx={{
            width: "22rem",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: "1 4px 6px rgba(35, 35, 156, 0.2)",
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 2,
          }}
        >
          <FormControl fullWidth>
            <Typography variant="h6">
              {mode === "edit" && data ? "Edit" : "Add New"} Status-Type
            </Typography>
            <Box marginTop="20px" display="flex" flexDirection="row" gap="1rem">
              <TextFormField
                label="Status Type Name"
                value={statusName?.name || ""}
                onChange={handleChange}
                type={"text"}
                required={true}
                name={"name"}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" marginTop="20px">
              <Button
                onClick={handleSubmit}
                variant="contained"
                sx={{
                  mx: 1,
                  backgroundColor: "#23239C",
                  "&:hover": {
                    backgroundColor: alpha("#23239C", 0.85),
                  },
                }}
              >
                Submit
              </Button>
            </Box>
          </FormControl>
        </Box>
      </Modal>
    </>
  );
};

export default AddStatusModal;
