import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = ({ data, seriesNames, xAxisTitle }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (data && seriesNames) {
      setChartData({
        series: [{ data: data }],
        options: {
          chart: {
            type: "bar",
            height: "140%",
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: "60%",
            },
          },
          colors: ["rgba(35, 35, 156, 0.6)"],
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: seriesNames,
            title: {
              text: xAxisTitle,
              style: {
                fontWeight: "normal",
                fontSize: "14px",
              },
            },
          },
        },
      });
    }
  }, [data, seriesNames, xAxisTitle]);

  if (!chartData) {
    return null; // or render a loading indicator
  }

  return (
    <Box>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="bar"
      />
    </Box>
  );
};

export default BarChart;
